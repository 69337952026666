<template>
    <div class="hospital pages pl-18 pr-18 mt-20" :class="[!Dark ? '' : 'dark', collapsed ? 'hide' : '' ]">
        <div class="scroll pt-20 pb-20 pl-20 pr-20 radius-10" v-loading="loading">
            <el-table class="listTable mt-20" :data="list" :class="[!Dark ? '' : 'dark']" style="width: 100%" v-if="list.length > 0">
                <!-- <el-table-column fixed prop="id" align="center" header-align="center" label="id" sortable /> -->
                <el-table-column fixed prop="number" align="center" header-align="center" label="医院编码" />
                <el-table-column fixed prop="name" align="center" header-align="center" label="患者姓名" />
                <el-table-column fixed prop="content" align="center" header-align="center" label="处方内容" />
                <el-table-column fixed prop="diagnosis" align="center" header-align="center" label="诊断内容" />
                <el-table-column fixed="right" align="center" header-align="center" label="操作" width="150">
                    <template slot-scope="{ row }">
                        <el-button class="font-14 primary" @click.stop="process(row)" type="primary">处理</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="listTable notData flex justify-center align-center" v-else>
                <div class="box-center text-center">
                    <img src="@/assets/images/error/empty.png" v-if="!Dark" alt />
                    <img src="@/assets/images/error/dark_empty.png" v-else alt />
                    <p>暂无数据</p>
                </div>
            </div>
            <div class="pages flex justify-center" v-if="list.length > 0">
                <el-pagination background layout="prev, pager, next, jumper" @current-change="selectPage" :current-page="page" prev-text="上一页" next-text="下一页" :total="count" :page-size="20"></el-pagination>
            </div>
        </div>

        <AddDrug :showPrescription="showPrescription" :content="content" :id="uid" :hospital_prescription_id="id"  :from="'hospital'" @drugClose="drugClose" @upData="upData" />
    </div>
</template>

<script>
    import { getHospitalPrescriptionList } from 'quest'; 
    import { AddDrug } from '_c/Model/index';
    export default {
        props:{
            Dark: Boolean,
            collapsed: Boolean
        },
        components:{
            AddDrug
        },
        data(){
            return{
                loading: true,
                list: [],
                count: 0,
                uid: '',
                id: 0,
                content: '',
                page: 1,
                showPrescription: false,
            }
        },
        methods:{
            getHospitalPrescriptionList(){
                getHospitalPrescriptionList({page: this.page}).then( res =>{
                    if(res.data.code == 100000){
                        this.list = res.data.data.list;
                        this.count = res.data.data.count;
                        this.loading = false;
                    }
                })
            },
            selectPage(page){
                this.page = page;
                this.getHospitalPrescriptionList();
            },
            process(item){
                this.showPrescription = !this.showPrescription;
                this.content = item.content;
                this.uid = item.uid;
                this.id = item.id;
            },
            drugClose(){
                this.showPrescription = !this.showPrescription;
                this.id = '';
                this.content = '';
            },
            upData(){
                this.showPrescription = !this.showPrescription;
                this.id = '';
                this.content = '';
                this.getHospitalPrescriptionList()
            }
        },

        created(){
            this.getHospitalPrescriptionList()
        }

    }
</script>

<style scoped lang="less">

    .hospital{
        height: ~'calc(100% - 70px)';
        .scroll{
            width: 100%;
            height: 100%;
            overflow-y: scroll;
            background: var(--white);
            .listTable{
                width: 100%;
                &::before,
                .el-table__fixed::before{
                    content: unset;
                }
                /deep/ tr.hover-row>td.el-table__cell{
                    cursor: pointer;
                    background-color: #ebf7ff;
                }
                /deep/ th.is-leaf,
                /deep/ th.is-leaf{
                    background-color: #fcfdff;
                    height: 52px;
                    border: none;
                }

                /deep/ .el-table__row{

                     .el-table__cell{
                        border: none;
                        height: 84px;
                    }
                }

                .header{
                    width: 37px;
                    height: 37px;
                }
                .primary{
                    background-color: var(--defalutColor);
                    color: var(--white);
                    border-color: var(--defalutColor);
                    font-weight: normal;
                    &:hover{
                        background-color: var(--defalutActiveColor);
                        border-color: var(--defalutActiveColor);
                    }
                }
                /deep/ .el-table__fixed::before,
                /deep/ .el-table__fixed-right::before{
                    content: unset;
                }
                &.notData{
                    height: ~'calc(100% - 70px)';
                }
            }
            & /deep/ .pages{
                margin-top: 20px;
                .btn-prev,
                .btn-next{
                    padding: 0px 20px;
                    float: left;
                    background: none;
                    transition: .3s;
                    &:hover{
                        color: var(--defalutColor);
                        transition: .3s;
                    }
                    &[disabled]{
                        color: #c0c4cc;
                    }
                }
                .el-pager{
                    float: left;
                    li{
                        background: none;
                        border: 1px solid #dcdee2;
                        transition: .3s;
                        &:hover{
                            border-color: var(--defalutColor);
                            color: var(--defalutColor);
                            transition: .3s;
                            cursor: pointer;
                        }
                        &.active{
                            border-color: var(--defalutColor);
                            color: var(--defalutColor);
                            transition: .3s;
                        }
                    }
                }
                .el-input__inner:focus{
                    border-color: var(--defalutColor);
                }
            }
        }
        &.dark {
            .scroll{
                background-color: #202025;
                /deep/ .listTable{
                    background-color: unset;

                    thead{
                        color: #c1c1c1;
                    }

                    th.el-table__cell{
                        background-color: black !important;
                        &>div{
                            color: #c1c1c1;
                        }
                    }

                    tr{
                        background: unset;
                    }

                    .el-table__row{

                        td>div{
                            color: #c1c1c1 !important;
                        }

                        .newbood_sugar{
                            span{
                                color: #c1c1c1 !important;
                            }
                        }
                    }
                }
                
                & /deep/ .el-popover{
                    background-color: #202025;
                    border-color: #0c0c0c;
                    h4{
                        color: #c1c1c1;
                    }
                    .el-divider{
                        background-color: #0c0c0c;
                    }

                    .popper__arrow{
                        border-bottom-color: #0c0c0c !important;
                        &::after{
                            border-bottom-color: #202025 !important;
                        }
                    }
                }

                /deep/ tr.hover-row>td.el-table__cell{
                    cursor: pointer;
                    background-color: #0c0c0c;
                }

                & /deep/ .pages{
                    .btn-prev{
                        color: #c1c1c1 !important;
                        &[disabled]{
                            color: #777777 !important;
                            &:hover{
                                color: #2c2c2e !important;
                            }
                        }
                        &:hover{
                            color: var(--defalutColor) !important;
                        }
                    }
                    .number{
                        color: #c1c1c1 !important;
                        border-color: #2c2c2e !important;
                        &.active{
                            color: var(--defalutColor) !important;
                            border-color: var(--defalutColor)!important;
                        }
                    }
                    .btn-next{
                        color: #c1c1c1 !important;
                        &[disabled]{
                            color: #777777 !important;
                            &:hover{
                                color: #2c2c2e !important;
                            }
                        }
                        &:hover{
                            color: var(--defalutColor) !important;
                        }
                    }
                    .el-pagination__jump{
                        color: #c1c1c1 !important;
                        input{
                            background-color: unset;
                            border-color: #2c2c22;
                            color: #c1c1c1;
                        }
                    }
                }
            }
        }
    }
</style>