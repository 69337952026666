<template>
    <div class="setting pages pl-18 pr-18 mt-20" :class="[!Dark ? '' : 'dark', collapsed ? 'hide' : '' ]">
        <div class="containers pt-20 pb-20 pl-20 pr-20 radius-10">
            <el-tabs class="pion-rel" v-model="tabs">
                <el-tab-pane label="个人设置" name="1" />
                <el-tab-pane label="账号密码" name="2" />
                <el-tab-pane label="机构设置" name="3" v-if="userRoleID <= 5" />
                <el-tab-pane label="开通胰岛素泵" name="4" v-if="Number(agencyInfo.is_open_pump) == 1" />
            </el-tabs>
            <div class="userInfo" v-if="tabs == '1'">
                <el-form :model="userInfo" label-width="100px">
                    <el-form-item label="头像">
                        <el-upload class="avatar-uploader" :action="uploadImgUrl" :data="params" :show-file-list="false" :on-success="upLoadImg" :before-upload="beforeAvatarUpload">
                            <img class="header pson-r-50" :src="userInfo.avatar != '' ? userInfo.avatar : defaultHeader">
                            <el-button class="font-12 font-normal">上传头像</el-button>
                        </el-upload>
                    </el-form-item>

                    <el-form-item label="昵称">
                        <el-input type="text" v-model="userInfo.nick" placeholder="请输入昵称" />
                    </el-form-item>
                    <el-form-item label="部门">
                        <el-input type="text" v-model="userInfo.department_title" :disabled="true" placeholder="请输入昵称" />
                    </el-form-item>
                    <el-form-item label="角色">
                        <el-input type="text" v-model="userInfo.agency_role_name" :disabled="true" placeholder="请输入昵称" />
                    </el-form-item>
                    <el-form-item label="  " class="save">
                        <el-button @click="handleSubmit(true)">保存</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="password" v-if="tabs == '2'">
                <el-form :model="account" label-width="100px">
                    <el-form-item label="旧密码">
                        <el-input type="password" v-model="account.old_pwd" />
                    </el-form-item>
                    <el-form-item label="新密码">
                        <el-input type="password" v-model="account.new_pwd" />
                    </el-form-item>
                    <el-form-item label="确认密码">
                        <el-input type="password" v-model="account.re_new_pwd" />
                    </el-form-item>
                    <el-form-item label="  " class="save">
                        <el-button @click="handleSubmit(false)">保存</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="agency" v-if="tabs == '3'">
                <el-form label-width="100px">
                    <el-form-item label="机构logo">
                        <el-upload class="avatar-uploader" :action="uploadImgUrl" :data="params" :show-file-list="false" :on-success="upLoadImg" :on-change="getFile" :before-upload="beforeAvatarUpload">
                            <img :src="logo && logo != '' ? logo : defaultLogo">
                            <el-button class="font-12 font-normal">上传机构logo</el-button>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="  " class="save">
                        <el-button @click="handleSubmit">保存</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="pum" v-if="tabs == '4'">
                <el-form :model="pumForm" :rules="pumRules" ref="pumForm" label-width="120px" class="pumForm">
                    <el-form-item label="手机号" prop="phone">
                        <el-input v-model="pumForm.phone" placeholder="请输入手机号"></el-input>
                    </el-form-item>
                    <el-form-item label="胰岛素泵编号" prop="sn">
                        <el-input v-model="pumForm.sn" placeholder="请输入胰岛素泵编号"></el-input>
                    </el-form-item>
                    <el-form-item label="开启远程注射">
                        <el-switch v-model="pumForm.delivery" active-color="#16cca6"></el-switch>
                    </el-form-item>
                    <el-form-item class="flex justify-end pt-20 mt-20 save">
                        <el-button class="remoteBtnS mt-10" type="primary" @click="pumSubmit()">开通</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>

    </div>
</template>

<script>
    import { getItem, setItem, upLoadImage } from '@/assets/js/Cache';
    import { upDateInfo, upDatePwd, upLoadLogo, openPumpInject } from 'quest'

    export default {
        props:{
            Dark: Boolean,
            collapsed: Boolean
        },
        data(){
            return{
                tabs: '1',
                defaultHeader: require('@/assets/images/error/notheader.png'),
                defaultLogo: require('@/assets/images/error/top_logo.jpg'),
                userRoleID: getItem('userInfo').role_id,
                agencyInfo: getItem('agencyInfo'),
                userInfo: {
                    avatar: '',
                    nick: '',
                    department_title: '',
                    agency_role_name: '',
                },
                uploadImgUrl: '',
                params: {},
                account: {
                    old_pwd: '',
                    new_pwd: '',
                    re_new_pwd: ''
                },
                logo: '',
                logo_base64: '',
                pumForm: {
                    phone: '',
                    sn: '',
                    delivery: false,
                },
                pumRules: {
                    phone: [
                        { required: true, message: '请输入手机号', trigger: 'blur' }
                    ],
                    sn: [
                        { required: true, message: '请输入胰岛素泵编号', trigger: 'blur' }
                    ]
                }
            }
        },
        methods:{
            upLoadImg(res){
                if(res.code == 100000){
                    if(this.tabs == '1'){
                        this.userInfo.avatar = res.data.file;
                    }else{
                        this.logo = res.data.file;
                    }
                }
            },

            beforeAvatarUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                   this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return isLt2M;
            },

            getFile(file){
                this.getBase64(file.raw).then(res => {
                    const params = res.split(',')
                    if (params.length > 0) {
                        this.logo_base64 = params[1]
                    }
                })
            },

            getUserInfo(){
                const userInfo = getItem('userInfo');
                if(userInfo && userInfo != ''){
                    this.userInfo = userInfo
                }
            },

            getAgencyInfo(){
                if(this.agencyInfo && this.agencyInfo != ''){
                    this.logo = this.agencyInfo.logo;
                }
            },

            handleSubmit(type){
                if(type){
                    if(type == true){
                        upDateInfo(this.userInfo).then( res =>{
                            if(res.data.code == 100000){
                                this.$message({
                                    message: res.data.msg,
                                    type: 'success'
                                });
                                setItem('userInfo', this.userInfo);
                                setTimeout( ()=>{
                                    location.reload();
                                },1000)
                            }
                        })
                    }else{
                        upLoadLogo({
                            logo: this.logo
                        }).then( res =>{
                            if(res.data.code == 100000){
                                this.$message({
                                    message: res.data.msg,
                                    type: 'success'
                                });
                                let info = getItem('agencyInfo');
                                info.logo = this.logo;
                                info.logo_base64 = this.logo_base64;
                                setItem('agencyInfo',info);
                                setTimeout( () =>{
                                    location.reload();
                                },2000)
                            }
                        })
                    }
                }else{
                    upDatePwd(this.account).then( res =>{
                        if(res.data.code != 100000){
                            this.$message({
                                message: res.data.msg,
                                type: 'warning',
                            });
                        }else{
                            this.$message({
                                message: '修改成功！',
                                type: 'success'
                            });
                        }
                    })
                }
            },
            
            // 获取图片转base64
            getBase64(file) {
                return new Promise(function (resolve, reject) {
                    const reader = new FileReader()
                    let imgResult = ''
                    reader.readAsDataURL(file)
                    reader.onload = function () {
                        imgResult = reader.result
                    }
                    reader.onerror = function (error) {
                        reject(error)
                    }
                    reader.onloadend = function () {
                        resolve(imgResult)
                    }
                })
            },

            pumSubmit() {
                this.$refs.pumForm.validate((valid) => {
                    if (valid) {
                        let data = {
                            mobile: this.pumForm.phone,
                            pump_sn: this.pumForm.sn,
                            is_remote_inject: this.pumForm.delivery
                        }
                        openPumpInject(data).then( res =>{
                            if (res.data.code == 100000) {
                                this.$message.success(res.data.msg);
                                this.openPumValue = false;
                                this.resetForm('pumForm')
                            } else {
                                this.$message.error(res.data.msg);
                            }
                        })
                    } else {
                        this.$message.error('请完善信息！');
                    }
                });
            },
        },
        mounted(){
            let data = upLoadImage();
            this.uploadImgUrl = data.url,
            this.params = data.params;
            this.getUserInfo();
            this.getAgencyInfo();
        }
    }
</script>

<style scoped lang="less">

    .setting{
        height: ~'calc(100% - 70px)';
        .containers{
            width: 100%;
            height: 100%;
            background: var(--white);

        }  
    }

    .containers /deep/ .el-tabs{

        .el-tabs__nav-wrap:after{
            height: 1px;
            background-color: #dcdee2;
        }

        .el-tabs__nav-scroll{
            padding-left: 20px;
        }

        .el-tabs__active-bar{
            height: 2px;
            background-color: var(--defalutColor);
        }
        
        .el-tabs__item.is-active{
            font-weight: 100;
            color: var(--defalutColor);
        }
        
        .el-tabs__item{
            transition: .3s;
        }
        
        .el-tabs__item:hover{
            transition: .3s;
            color: var(--defalutColor);
        }

        .el-tabs__content{
            overflow: visible;
        }
    }

    .containers /deep/ .el-form{
        width: 450px;
        margin-top: 45px;

        .el-form-item{
            display: flex;
            align-items: center;

            .el-form-item__label{
                font-size: 12px;
                font-weight: normal;
            }

            .el-form-item__content{
                display: flex;
                align-items: center;
                margin-left: 50px !important;

                input{
                    width: 290px;
                    height: 32px;
                    padding: 4px 7px;
                    border-color: #dcdee2;
                    font-size: 12px;

                    &::placeholder{
                        font-size: 12px;
                    }
                }
            }
        }

        .avatar-uploader{

            .el-upload{
                border:  unset;
                line-height: unset;
                display: flex;
                align-items: center;

                img{
                    &.header{
                        width: 50px;
                        height: 50px;
                    }

                    width: 125px;
                    height: 45px;
                    object-fit: contain;
                }

                button{
                    width: 145px !important;
                    height: 32px;
                    line-height: 7px;
                    margin-left: 20px;
                    width: 100%;
                    background: #f0f2f5;
                    border-radius: 4px;
                    border: 1px solid #d7dbe0;
                }
            }
        }

        .save{
            button{
                width: 290px;
                height: 44px;
                background: var(--defalutActiveColor);
                border-color: #13be9b;
                color: white;
                font-weight: normal;
                font-size: 12px;
            }
        }
    }

    .setting.dark{
        .containers{
            background: #202025 !important;
        }
        .containers /deep/ .el-tabs{

            .el-tabs__item{
                color: #c1c1c1 !important;
                &.is-active{
                    color: var(--defalutColor) !important; 
                }
            }

            .el-tabs__nav-wrap:after{
                background-color: #2c2c2e;
            }

            .buttons{
                input{
                    background: unset;
                    border-color: #2c2c2e;

                    &::placeholder{
                        color: #e8e8e8;
                    }
                }
                button{
                    background: unset;
                    color: white !important;
                    border-color: #2c2c2e;
                }
            }
        }

        .userInfo,
        .containers{
            & /deep/ .el-form-item__label{
                color: #c1c1c1;
            }

            .el-upload{
                button{
                    background: unset !important;
                    border-color: #2c2c2e !important;
                    color: #c1c1c1;
                }
            }
            & /deep/ .el-form-item__content{
                input{
                    color: #c1c1c1;
                    background-color: unset;
                    border-color: #2c2c2e !important;
                }
            }
        }
    }

</style>