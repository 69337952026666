<template>
    <div class="organization pages pl-18 pr-18 mt-20" :class="[!Dark ? '' : 'dark', collapsed ? 'hide' : '' ]" v-loading="loading">
        <div class="scroll pt-20 pb-20 pl-20 pr-20 radius-10">
            <el-tabs class="pion-rel" v-model="tab">
                <el-tab-pane label="部门管理" name="1" />
                <el-tab-pane label="角色管理" name="2" />
                <el-tab-pane label="成员管理" name="3" />
                <div class="buttons pion-abs flex">
                    <el-input class="mr-20" v-if="tab == '3'" prefix-icon="el-icon-search" placeholder="请输入成员姓名" @input="searchUser(selectDoctor)" v-model="selectDoctor"></el-input>
                    <el-button class="line-height-32 font-12 ml-15 font-normal" :icon="tab == '3' ? 'el-icon-s-custom' : '' " plain @click="showAdd(Number(tab))">{{ tab == '1' ? '添加部门' : ( tab == '2' ? '添加角色' : '添加成员' )  }}</el-button>
                </div>
            </el-tabs>
            <div class="department" v-if="tab == '1' && !loading">
                <template  v-if="Department.length > 0 ">
                    <Tree :list="Department" @selectCard="selectCard" @showDrawer="showDrawer" @editDep="editDep" @delDep="delDep" />
                </template>
            </div>
            <div class="role" v-if="tab == '2'">
                <Tree :list="roleList" @selectCard="selectCard" @showDrawer="showDrawer" @editDep="editRole" @delDep="delRole" />
            </div>
            <div class="doctorList" v-if="tab == '3'">
                <el-table class="listTable mt-20" :data="doctorList" v-if="doctorList.length > 0 " style="width: 100%">
                    <el-table-column fixed label="头像" align="center" header-align="center" width="150">
                        <template slot-scope="{ row }">
                            <img class="header radius-50" :src="row.avatar != '' && row.avatar != null ? row.avatar : notDate" alt="">
                        </template>
                    </el-table-column>
                    <el-table-column fixed prop="name" align="center" header-align="center" label="姓名" />
                    <el-table-column fixed prop="department_title" align="center" header-align="center" label="部门" />
                    <el-table-column fixed prop="role_title" align="center" header-align="center" label="角色" />
                    <el-table-column fixed="right" align="center" header-align="center" label="操作" width="150">
                        <template slot-scope="{ row }">
                            <el-popover placement="bottom" :append-to-body="false" width="100" trigger="hover">
                                <div class="content popover pt-10">
                                    <h4 class="text-center font-16">更多菜单</h4>
                                    <el-divider class="line"></el-divider>
                                    <div class="pop-button text-center">
                                        <el-button class="font-14" @click.stop="editDoctor(row.id)" type="text">查看详情</el-button>
                                        <el-button class="font-14" @click.stop="deleteDoctor(row.id)" type="text">删除成员</el-button>
                                    </div>
                                </div>
                                <i class="el-icon-more font-weight font-20" slot="reference"></i>
                            </el-popover>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pages flex justify-center" v-if="doctorList.length > 0 ">
                    <el-pagination background layout="prev, pager, next, jumper" @current-change="selectPage" :current-page="page" prev-text="上一页" next-text="下一页" :total="count" :page-size="12"></el-pagination>
                </div>
                <div class="empty" v-else>
                    <div class="box-center">
                        <img src="@/assets/images/error/empty.png" v-if="!Dark" alt />
                        <img src="@/assets/images/error/dark_empty.png" v-else alt />
                        <p>暂无数据</p>
                    </div>
                </div>
            </div>
        </div>
        <el-drawer class="organizationDrawer" :visible.sync="drawerShow" :modal-append-to-body="false" size="60%" :with-header="false">
            <h4 class="title solid-bottom pt-15 pr-17 pb-15 pl-17 font-14 font-weight">
                {{drawer.title}}
            </h4>
            <el-table class="listTable mt-20" :data="drawer.Info" v-if="drawer.Info.length > 0" style="width: 100%">
                <el-table-column fixed label="头像" align="center" header-align="center" width="150">
                    <template slot-scope="{ row }">
                        <img class="header radius-50" :src="row.avatar != '' && row.avatar != null ? row.avatar : notDate" alt="">
                    </template>
                </el-table-column>
                <el-table-column fixed prop="name" align="center" header-align="center" label="姓名" />
                <el-table-column fixed prop="department_title" align="center" header-align="center" label="部门" />
                <el-table-column fixed prop="role_title" align="center" header-align="center" label="角色" />
                <el-table-column fixed="right" align="center" header-align="center" label="操作" width="100">
                    <template slot-scope="{ row }">
                        <el-popover placement="bottom" width="100" :append-to-body="false" trigger="hover">
                            <div class="content popover pt-10">
                                <h4 class="text-center font-16">更多菜单</h4>
                                <el-divider class="line"></el-divider>
                                <div class="pop-button text-center">
                                    <el-button class="font-14" @click.stop="editDoctor(row.id)" type="text">查看详情</el-button>
                                    <el-button class="font-14" @click.stop="deleteDoctor(row.id,true)" type="text">删除成员</el-button>
                                </div>
                            </div>
                            <i class="el-icon-more font-weight font-20" slot="reference"></i>
                        </el-popover>
                    </template>
                </el-table-column>
            </el-table>
            <div class="empty" v-else>
                <div class="box-center">
                    <img src="@/assets/images/error/empty.png" v-if="!Dark" alt />
                    <img src="@/assets/images/error/dark_empty.png" v-else alt />
                    <p>暂无数据</p>
                </div>
            </div>
        </el-drawer>
        <Department :Show="depShow" :Dark="Dark" @close="depShow = false, DepId = 0" :id="DepId" @save="save" />
        <AddRole :show="addRole" :Dark="Dark" :info="roleInfo" @close="addRole = false" @save="saveRole" />
        <AddDoctor :show="addDoctors" :Dark="Dark" :id="doctorId" @close="addDoctors = false, doctorId = '' " @save="saveDoctor" />
    </div>
</template>

<script>
    import { getDepartment, getDoctorList, departmentDelete, getRoleList, deleteRole, deleteDoctor } from 'quest'
    import { Department, AddRole, AddDoctor, Tree } from '_c/Model/index'
    export default {
        props:{
            Dark: Boolean,
            collapsed: Boolean
        },
        components: {
            Department,
            AddRole,
            AddDoctor,
            Tree
        },
        data(){
            return{
                loading: true,
                tab: '1',
                Department: [],
                drawerShow: false,
                drawer:{
                    title: '',
                    Info: [],
                    id: '',
                },
                notDate: require('@/assets/images/error/notheader.png'),
                departmentList: [],
                depShow: false,
                parent: '',
                father: '',
                child: '',
                grandson: '',
                DepId: 0,
                roleList: [],
                doctorList:[],
                addRole: false,
                roleInfo: {},
                selectDoctor: '',
                rawDoctorList: [],
                addDoctors: false,
                doctorId: 0,
                count: 0,
                page: 1,
            }
        },
        watch:{
            drawerShow(val){
                if(!val){
                    this.drawer.id = '';
                }
            },
            tab(val){
                if(val == '1' ){
                    this.loading = true;
                    this.getDepartmentList();
                }else if(val == '2'){
                    this.loading = true;
                    this.getRoleList();
                }else{
                    this.loading = true;
                    this.getDoctorList();
                }
            }
        },
        methods:{
            showAdd(type){
                if(type == 1 ){
                    this.depShow = true;
                }else if(type == 2){
                    this.addRole = true;
                }else{
                    this.doctorId = '';
                    this.addDoctors = true;
                }
            },

            getDepartmentList(){
                getDepartment().then(res => {
                    if(res.data.code == 100000){
                        let list = res.data.data;
                        this.recursionAppend(list)
                    }
                })
            },

            getRoleList(){
                getRoleList().then( res =>{
                    if(res.data.code == 100000){
                        let roleList = res.data.data;
                        this.recursionAppend(roleList)
                    }
                })
            },

            getDoctorList(id){
                getDoctorList({[ id && id != 0 ? 'department_id' : '' ]: id && id != 0 ? id : '' }).then( res => {
                    if(res.data.code == 100000){
                        this.loading = false
                        if(id){
                            this.drawer.Info = res.data.data.list;
                        }
                        this.doctorList = res.data.data.list,
                        this.rawDoctorList = res.data.data.list;
                        this.count = res.data.data.count;
                    }
                })
            },

            searchUser(val){
                let list = this.doctorList,
                    nList = [],
                    str = ['',...val,''].join('.*'),
                    reg = new RegExp(str)
                if(val != '' ){

                    list.map( item => {
                        if(reg.test(item.name)){
                            nList.push(item);
                        }
                    })
                    this.doctorList = nList;
                }else{
                    this.doctorList = this.rawDoctorList;
                }
            },

            showDrawer(item){
                this.drawerShow = true,
                this.drawer.title = item.title,
                this.drawer.id = item.id;
                this.getDoctorList(item.id)
            },

            save(){
                this.depShow = false,
                this.loading = true,
                this.getDepartmentList();
            },

            editDep(id){
                this.showAdd(1);
                this.DepId = Number(id);
            },

            delDep(id){
                this.$confirm('确定删除该部门吗？','提示',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    customClass: !this.Dark ? '' : 'darkDetailsDialogBox'
                }).then( () =>{
                    departmentDelete({id}).then( res => {
                        if(res.data.code == 100000){
                            this.$message({
                                message: '删除成功！',
                                type: 'success',
                            });
                            this.loading = true;
                            setTimeout( () => {
                                this.getDepartmentList();
                            },1000)
                        }
                    })
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });
                });
            },

            saveRole(){
                this.addRole = false,
                this.loading = true,
                this.getRoleList();
            },

            editRole(id,info){
                this.showAdd(2);
                this.roleInfo = info;
            },

            delRole(id){
                this.$confirm('确定删除该角色吗？','提示',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    customClass: !this.Dark ? '' : 'darkDetailsDialogBox'
                }).then( () =>{
                    deleteRole({id}).then( res => {
                        if(res.data.code == 100000){
                            this.$message({
                                message: '删除成功！',
                                type: 'success',
                            });
                            setTimeout( () => {
                                this.getRoleList();
                            },1000)
                        }
                    })
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });
                });
            },

            selectCard(id){
                switch(this.tab){
                    case '1':
                        this.closeCard(id,this.Department)
                    break;
                    case '2': 
                        this.closeCard(id,this.roleList)
                    break;
                }
            },

            saveDoctor(){
                this.addDoctors = false,
                this.getDoctorList();
            },

            selectPage(page){
                this.page = page;
            },

            editDoctor(id){
                this.addDoctors = true,
                this.doctorId = id;
            },

            deleteDoctor(id,type){

                this.$confirm('确定删除该部门吗？','提示',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    customClass: !this.Dark ? '' : 'darkDetailsDialogBox'
                }).then( () =>{
                    deleteDoctor({id}).then( res => {
                        if(res.data.code == 100000){
                            this.$message({
                                message: '删除成功！',
                                type: 'success',
                            });
                            setTimeout( () => {
                                if(type){
                                    this.getDoctorList(this.drawer.id)
                                }else{
                                    this.getDoctorList();
                                }
                            },1000)
                        }
                    })
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });
                });
            },

            closeCard(id,arr){
                arr && arr.map( item => {
                    if( item.id == id ){
                        item.showChild = !item.showChild
                    }else{
                        if(item.childs.length > 0 ){
                            this.closeCard(id,item.childs);
                        }else{
                            item.showChild = false;
                        }
                    }
                })
            },

            recursionAppend (arr){
                arr.map( item =>{
                    item.showChild = false;
                    if(item.childs){
                        this.recursionAppend(item.childs);
                    }
                })
                switch(this.tab){
                    case '1':
                        this.Department = arr;
                    break;
                    case '2':
                        this.roleList = arr;
                    break;
                }
                this.loading = false;
            }
        },
        mounted(){
            this.getDepartmentList();
        }
    }
</script>

<style scoped lang="less">
    .organization{
        height: ~'calc(100% - 70px)';
        padding-bottom: 10px;
        .scroll{
            width: 100%;
            height: 100%;
            overflow-y: scroll;
            background: var(--white);

            .role{

                /deep/ .el-collapse{
                    border-top: unset;
                    border-bottom: unset;

                    .fath:hover{
                        .father{
                            display: block;
                        }
                    }

                    .sec:hover{
                        .section{
                            display: block;
                        }
                    }
                    .chi:hover{
                        .children{
                            display: block;
                        }
                    }

                    .el-collapse-item__wrap{
                        border-bottom: unset;
                    }

                    .el-collapse-item__content{
                        padding-bottom: 0px;
                    }

                    .el-collapse-item__header{
                        border-bottom: unset;

                        & > .el-icon-arrow-right{
                            display: none;
                        }
                    }
                }

                .list{
                    width: 100%;
                    border-color: #dcdee2;
                    cursor: pointer;

                    &:nth-last-child(1){
                        border: none;
                    }

                    p{
                        color: #666;
                    }

                    .icons{
                        i{
                            margin-right: 30px;
                            color: #979797;
                        }
                    }
                }
            }
        }

        /deep/ .el-tabs{

            .el-tabs__nav-wrap:after{
                height: 1px;
                background-color: #dcdee2;
            }

            .el-tabs__nav-scroll{
                padding-left: 20px;
            }

            .el-tabs__active-bar{
                height: 2px;
                background-color: var(--defalutColor);
            }
            
            .el-tabs__item.is-active{
                font-weight: 100;
                color: var(--defalutColor);
            }
            
            .el-tabs__item{
                transition: .3s;
            }
            
            .el-tabs__item:hover{
                transition: .3s;
                color: var(--defalutColor);
            }

            .el-tabs__content{
                overflow: visible;
            }

            .buttons{
                top: -60px;
                right: 0px;
                z-index: 999;

                .el-input{
                    width: 200px;
                    height: 32px;

                    .el-input__prefix{
                        top: -4px;
                    }
                }

                input{
                    width: 200px;
                    height: 32px;

                    &::placeholder{
                        font-size: 12px;
                    }

                    &:hover,
                    &:focus{
                        border-color: var(--defalutColor);
                    }
                }

                button{
                    height: 32px;
                    width: 120px;
                    padding: 0px 10px;
                    transition: .3s;
                    line-height: 7px;

                    &:hover{
                        border-color: var(--defalutActiveColor) !important;
                        color: var(--defalutActiveColor) !important;
                        transition: .3s;
                    }
                    &:focus{
                        background: none;
                        border-color: var(--defalutActiveColor) !important;
                        color: var(--defalutActiveColor) !important;
                    }
                }
            }
        }

        .doctorList{
            width: 100%;
            height: 100%;
        }

        .doctorList /deep/ .listTable{

            &::before,
            .el-table__fixed::before{
                content: unset;
            }

            .el-table__fixed-right:before{
                content:  unset;
            }

            th.el-table__cell{
                background-color: #fcfdff;
                height: 52px;
                border: none;
            }

            .el-table__row{
                .el-table__cell{
                    border: none;
                    height: 84px;
                    background-color: unset;

                    .normal{
                        color: var(--defalutColor);
                        span{
                            color: #606266;
                        }
                    }

                    .high{
                        color: var(--bloodSugarHigh);
                        span{
                            color: #606266;
                        }
                    }

                    .low{
                        color: var(--bloodSugarLow);
                        span{
                            color: #606266;
                        }
                    }
                }
            }

            .header{
                width: 37px;
                height: 37px;
            }
        }

        .scroll /deep/ .pages{
            .btn-prev,
            .btn-next{
                padding: 0px 20px;
                float: left;
                background: none;
                transition: .3s;
                &:hover{
                    color: var(--defalutColor);
                    transition: .3s;
                }
                &[disabled]{
                    color: #c0c4cc;
                }
            }
            .el-pager{
                float: left;
                li{
                    background: none;
                    border: 1px solid #dcdee2;
                    transition: .3s;
                    &:hover{
                        border-color: var(--defalutColor);
                        color: var(--defalutColor);
                        transition: .3s;
                        cursor: pointer;
                    }
                    &.active{
                        border-color: var(--defalutColor);
                        color: var(--defalutColor);
                        transition: .3s;
                    }
                }
            }
            .el-input__inner:focus{
                border-color: var(--defalutColor);
            }
        }

        &.dark{
            .scroll{
                background: #202025 !important;

                .department /deep/ .el-collapse,
                .role /deep/ .el-collapse{

                    .el-collapse-item__wrap{
                        background-color: unset;
                    }

                    .list{
                        border-color: #2a2a31 !important;
                        p{
                            color: #c1c1c1 !important;
                        }
                    }

                    .el-collapse-item__header{
                        background-color: unset;
                    }
                }

                .doctorList /deep/ .listTable{
                    background-color: unset;

                    thead{
                        color: #c1c1c1;
                    }

                    th.el-table__cell{
                        background-color: black !important;
                        &>div{
                            color: #c1c1c1;
                        }
                    }

                    tr{
                        background: unset;
                    }

                    .el-table__row{

                        td>div{
                            color: #c1c1c1 !important;
                        }

                        .newbood_sugar{
                            span{
                                color: #c1c1c1 !important;
                            }
                        }
                    }
                }

                /deep/ .pages{
                    .el-pagination__jump{
                        color: white;
                        input{
                            background-color: unset;
                            border-color: #aeaeae;
                            color: white;
                        }
                    }
                }
            }

            .scroll /deep/ .el-tabs{

                .el-tabs__item{
                    color: #c1c1c1 !important;
                    &.is-active{
                       color: var(--defalutColor) !important; 
                    }
                }

                .el-tabs__nav-wrap:after{
                    background-color: #2c2c2e;
                }

                .buttons{
                    input{
                        background: unset;
                        border-color: #2c2c2e;

                        &::placeholder{
                            color: #e8e8e8;
                        }
                    }
                    button{
                        background: unset;
                        color: #c1c1c1 !important;
                        border-color: #2c2c2e;
                    }
                }
            }

            & /deep/ .pages{
                .btn-prev{
                    color: #c1c1c1 !important;
                    &[disabled]{
                        color: #2c2c2e !important;
                        &:hover{
                            color: #2c2c2e !important;
                        }
                    }
                    &:hover{
                        color: var(--defalutColor) !important;
                    }
                }
                .number{
                    color: #c1c1c1 !important;
                    border-color: #2c2c2e !important;
                    &.active{
                        color: var(--defalutColor) !important;
                        border-color: var(--defalutColor)!important;
                    }
                }
                .btn-next{
                    color: #c1c1c1 !important;
                    &[disabled]{
                        color: #2c2c2e !important;
                        &:hover{
                            color: #2c2c2e !important;
                        }
                    }
                    &:hover{
                        color: var(--defalutColor) !important;
                    }
                }
                .el-pagination__jump{
                    color: #c1c1c1 !important;
                    input{
                        background-color: unset;
                        border-color: #2c2c22;
                        color: #c1c1c1;
                    }
                }
            }

            .statistics{
                background: #2a2a31 !important;
            }

            /deep/ .organizationDrawer{
                .el-drawer{
                    background-color: #0c0c0c;
                }
                .el-drawer__body{
                    h4{
                        color: #c1c1c1;
                        border-bottom-color: #2a2a31;
                    }
                    .listTable{
                        background-color: unset;
                        thead{
                            color: #c1c1c1;
                        }

                        th.el-table__cell{
                            background-color: black !important;
                            &>div{
                                color: #c1c1c1;
                            }
                        }

                        tr{
                            background: unset;
                        }

                        .el-table__row{

                            td>div{
                                color: #c1c1c1 !important;
                            }

                            &:hover,
                            &.hover-row{
                                background-color: #585858;
                            }

                            .newbood_sugar{
                                span{
                                    color: #c1c1c1 !important;
                                }
                            }
                        }
                    }
                }
            }
            /deep/ .el-popover{
                background-color: #202025;
                border-color: #0c0c0c;
                h4{
                    color: #c1c1c1;
                }
                .el-divider{
                    background-color: #0c0c0c;
                }

                .popper__arrow{
                    border-bottom-color: #0c0c0c !important;
                    &::after{
                        border-bottom-color: #202025 !important;
                    }
                }
            }
        }
    }

</style>

<style lang="less">

    .organizationDrawer{
        
        .title{
            border-color:#e8eaec;
            line-height: 1;
            color: #17233d;
        }
        
        .listTable{

            &::before,
            .el-table__fixed::before{
                content: unset;
            }

            .el-table__fixed-right:before{
                content:  unset;
            }

            th.el-table__cell{
                background-color: #fcfdff;
                height: 52px;
                border: none;
            }

            .el-table__row{

                &:hover,
                &.hover-row{
                    background-color: #ebf7ff;
                    cursor: pointer;
                }

                .el-table__cell{
                    border: none;
                    height: 84px;
                    background-color: unset;

                    .normal{
                        color: var(--defalutColor);
                        span{
                            color: #606266;
                        }
                    }

                    .high{
                        color: var(--bloodSugarHigh);
                        span{
                            color: #606266;
                        }
                    }

                    .low{
                        color: var(--bloodSugarLow);
                        span{
                            color: #606266;
                        }
                    }
                }
            }

            .header{
                width: 37px;
                height: 37px;
            }
        }
    }

    .darkDetailsDialogBox{
        background-color: #0c0c0c;
        border-color: #0c0c0c;
        .el-message-box__title,
        .el-message-box__message{
            color: #c1c1c1;
        }
        .el-message-box__btns{
            .el-button:nth-child(1){
                background-color: #202025;
                border-color: #202025;
                color: #c1c1c1;
                &:hover{
                    color: var(--defalutColor) !important;
                }
            }
        }
    }
</style>