<template>
    <div class="addPlan">
        <!--  :style="{ 'height': isIpad ? '' : '80%', 'width':   }" -->
        <el-dialog :title="from == 'details' ? '控糖方案' : '方案模板'" class="addPlanDialog" :class="[!Dark ? '' : 'dark']" :visible.sync="isShow" :before-close="close" :close-on-click-modal="false" :close-on-press-escape="false" :append-to-body="true">
            <el-tabs class="tabs" type="card" v-model="tab">
                <el-tab-pane :label="[ from == 'details' ? '控糖方案' : '方案模板' ]" name="0" />
                <el-tab-pane label="方案预览" name="1" v-if="getData || DetailGetTemp" />
            </el-tabs>

            <el-form :model="data" v-if="tab == '0'" label-position="left">

                <div class="content-info">
                    <div class="left pion-rel">
                        <el-form-item label="方案名称">
                            <el-input v-model="data.title" type="text" placeholder="请输入方案名称" 
                            :disabled="getData && !DetailGetTemp && from != 'programme'"  :style="{'width': !isIpad ? '200px' : (!getData ? '75px' : '190px' ) }"></el-input>

                            <el-dropdown class="dropDown" trigger="click" style="margin-left: 10px" @command="selectTemp" v-if=" ( !getData && from == 'details' )">
                                <el-button plain>
                                    <span>{{data.title == '' ? '控糖方案' : data.title }}</span>
                                    <i class="el-icon-arrow-down"></i>
                                </el-button>
                                <el-dropdown-menu :append-to-body="false" :popper-append-to-body="false" slot="dropdown">
                                    <el-dropdown-item v-for="(item,index) in templateList" :command="item.id" :key="index">{{item.title}}</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </el-form-item>
                        <el-form-item label="开始日期" v-if="from == 'details'">
                            <el-date-picker 
                                :append-to-body="false"
                                type="date"
                                :style="{'width': isIpad ? '190px' : '200px' }"
                                placeholder="选择开始日期" 
                                format="yyyy-MM-dd" 
                                @change="getWeeklist(data.start_day,data.days)"
                                v-model="data.start_day"
                                :options="disabledBeforeTime"
                                :disabled="getData && !DetailGetTemp"
                            >
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="执行天数">
                            <el-select :popper-append-to-body="false"
                                v-model="data.days"
                                :style="{'width': isIpad ? '190px' : '200px' }"
                                placeholder="请选择执行天数" 
                                @change="getWeeklist(data.start_day,data.days)"
                                :disabled="getData && !DetailGetTemp && from != 'programme'"
                            >
                                <el-option v-for="item in ['三天','七天']" :value="item" :key="item">{{item}}</el-option>
                            </el-select>
                        </el-form-item>
                    </div>

                    <div class="right">
                        <div class="weekList" v-if="from == 'details' ">
                            <div
                                class="list"
                                v-for="(item,index) in weekList"
                                :class="[ index == activeDate ? 'active' : '' ]"
                                :key="index"
                                @click="changeDate(index)"
                            >
                                <h4 class="date">{{ item.day }} <span>{{item.week}}</span></h4>
                                <!-- <p class="week">{{  }}</p> -->
                            </div>
                        </div>
                        <div class="weekList" v-else>
                            <div 
                                class="list" 
                                v-for="(item,index) in tempDays"
                                :class="[ index == activeDate ? 'active' : '' ]"
                                @click="changeDate(index)"
                                :key="index"
                            >
                                <p class="week mt-10 text-center font-weight">{{item == 1 ? '第一天' : item == 2 ? '第二天' : item == 3 ? '第三天' : item == 4 ? '第四天' : item == 5 ? '第五天' : item == 6 ? '第六天' : '第七天' }}</p>
                            </div>
                        </div>
                        <div class="total pt-10 pr-20 pb-10 pl-20 radius-5" v-if="!getData || DetailGetTemp">
                            <div class="watel example">
                                <h4>碳水：</h4>
                                <p><span>{{carbohydratesVal != 0 ? Number(carbohydratesVal).toFixed(1) : '--' }}</span>克</p>
                            </div>
                            <div class="pill example">
                                <h4>热量：</h4>
                                <p><span>{{calorie != 0 ? Number(calorie).toFixed(1) : '--' }}</span>千卡</p>
                            </div>
                            <div class="insulin example">
                                <h4>胰岛素：</h4>
                                <p><span>{{insulinVal != 0 ? Number(insulinVal).toFixed(1) : '--' }}</span>U</p>
                            </div>
                            <div class="pill example">
                                <h4>用药：</h4>
                                <p><span>{{pillVal != 0 ? pillVal : '--' }}</span>次</p>
                            </div>
                        </div>
                        <div class="total pt-10 pr-20 pb-10 pl-20 radius-5" v-else>
                            <div class="watel example">
                                <h4>碳水:</h4>
                                <p><span>{{total.carbohydrate != 0 ? Number(total.carbohydrate).toFixed(1) : '--' }}</span>克</p>
                            </div>
                            <div class="pill example">
                                <h4>热量：</h4>
                                <p><span>{{total.calorie != 0 ? Number(total.calorie).toFixed(1) : '--' }}</span>千卡</p>
                            </div>
                            <div class="insulin example">
                                <h4>胰岛素：</h4>
                                <p><span>{{total.insulin != 0 ? Number(total.insulin).toFixed(1) : '--' }}</span>U</p>
                            </div>
                            <div class="pill example">
                                <h4>用药：</h4>
                                <p><span>{{total.medicine != 0 ? total.medicine : '--' }}</span>次</p>
                            </div>
                        </div>
                        <div class="btnList">
                            <div class="item" @click="showAdd(1)">
                                <img src="@/assets/images/icons/u.png" alt />
                                <span>+ 饮食</span>
                            </div>
                            <div class="item" @click="showAdd(2)">
                                <img src="@/assets/images/icons/ci.png" alt />
                                <span>+ 用药</span>
                            </div>
                            <div class="item" @click="showAdd(3)">
                                <img src="@/assets/images/icons/ku.png" alt />
                                <span>+ 胰岛素</span>
                            </div>
                            <div class="item" @click="showAdd(4)">
                                <img src="@/assets/images/icons/kal.png" alt />
                                <span>+ 运动</span>
                            </div>
                        </div>
                        <div class="addList" v-if="num > 0 ">
                            <MealsPlan
                                v-for="(item,index) in showList"
                                :plan="item"
                                :key="index"
                                :Dark="Dark"
                                :idx="index"
                                :getData="getData"
                                :DetailGetTemp="DetailGetTemp"
                                @edit="edit"
                                @del="del"
                            />
                        </div>
                        <div class="empty" v-else>
                            <img src="@/assets/images/error/empty.png" v-if="!Dark" alt />
                            <img src="@/assets/images/error/dark_empty.png" v-else alt />
                            <p>暂无数据</p>
                        </div>
                    </div>
                </div>

            </el-form>

            <ShowPlan
                v-else
                :data="plan"
                :getTemp="getData"
                :tempDays="tempDays"
                :weekList="weekList"
                :Dark="Dark"
                :from="parameter.from"
            />

            <div slot="footer" class="dialog-footer">
                <el-button class="delete" @click="deletePlan(id)" v-show="getData">{{ from == 'details' ? '删除控糖方案' : '删除方案模板' }}</el-button>
                <el-button @click="close">取消</el-button>
                <el-button class="save" @click="submit">保存</el-button>
            </div>
        </el-dialog>
        <AddPlanInfo
            :addParameter="addParameter"
            :Dark="Dark"
            @closeAdd="closeAdd"
            @addPlan="addPlan"
            @editPlan="editPlan"
        />
    </div>
</template>

<script>
    import { getUserPlan, planSave, foodRecordAdd, medicineRecordAdd, sportsRecordAdd, foodRecordDel, medicineRecordDel, sportsRecordDel, planSaveTemp, templateGetList, templateGetInfo, userPlanDelete, doctorPlanDelete} from "quest";
    import { setItem, removeItem } from '_js/Cache';
    export default {
        props:{
            parameter: Object,
            Dark: Boolean
        },
        components: {
            'AddPlanInfo': () => import('./AddPlanInfo'),
            'MealsPlan': () => import('./MealsPlan'),
            'ShowPlan': () => import('./ShowPlan'),
        },
        watch: {
            parameter:{
                handler(obj){
                    this.isShow = obj.show,
                    this.from = obj.from;
                    this.addParameter.from = obj.from;
                    this.id = obj.id;
                    if(obj.from == 'details'){
                        obj.id != 0 ? this.getPatientPlan(obj.id) : '';
                        this.templateGetList()
                    }else if(obj.from == 'programme'){
                        if(obj.isAddTemp){
                            this.loading = false;
                        }else{
                            this.selectTemp(obj.id);
                        }
                    }
                },
                deep: true
            },
            isShow(val){
                if(val){
                    this.preventClose();
                    this.getWeeklist(this.data.start_day,this.data.days);
                }else{
                    this.DetailGetTemp = false;
                    this.getData = false;
                    return false;
                }
            },
            plan:{
                handler(val){
                    this.showList = val['day' + Number( this.activeDate + 1 )];
                    if(!this.getData){
                        this.carbohydrates(val['day' + ( this.activeDate + 1 )]);
                    }
                    this.num = val['day' + ( this.activeDate + 1 )].length;
                    this.data.plan = val;
                },
                deep: true
            },
            activeDate(val){
                this.showList = this.plan['day' + ( val + 1 )];
                this.num = this.showList.length;
                if(!this.getData || this.DetailGetTemp){
                    this.carbohydratesVal = 0,
                    this.insulinVal = 0,
                    this.pillVal = 0;
                    this.carbohydrates(this.showList)
                }else{
                    this.total = this.getAllData['day' + ( val + 1 ) ].total;
                }
                this.addParameter.show = false;
            },
            'data.start_day'(val){
                let getData = this.plan,
                    plan = {};
                this.loading = true;
                Object.keys(getData).forEach( (key) =>{
                    plan[key] = [];
                    let day = parseInt(key.split('y')[1]),
                        detail = getData[key];
                    if(detail.length){
                        detail.map( item => {
                            item[item.kind == 1 ? 'food_time' : item.kind == 3 ? 'sports_time' : 'medicine_time' ] = this.timeCalc(val, ( day - 1 ));
                        })
                        plan[key] = detail;
                        this.loading = false;
                    }else{
                        this.loading = false;
                    }
                })
                this.plan = plan;
            },
            carbohydratesVal(val){
                this.carbohydratesVal = this.decimalPointIntercept(val);
            },
        },
        data(){
            return {
                tab: '0',
                loading: true,
                isShow: false,
                from: '',
                id: 0,
                getData: false,
                data:{
                    title: '',
                    start_day: new Date(),
                    days: '七天',
                    plan:{}
                },
                templateList:[],
                disabledBeforeTime: {
                    disabledDate (date) {
                        return date && date.valueOf() < Date.now() - 86400000;
                    }
                },
                plan:{
                    day1:[],
                    day2:[],
                    day3:[],
                    day4:[],
                    day5:[],
                    day6:[],
                    day7:[],
                },
                weekList:[],
                tempDays: 7,
                activeDate: 0,
                carbohydratesVal: 0,
                calorie: 0,
                insulinVal: 0,
                pillVal: 0,
                total:{},
                addModelShow: false,
                addType: 0,
                addParameter:{
                    show: false,
                    title: '',
                    plan: {}
                },
                showList: [],
                num: 0,
                DetailGetTemp: false,
                getAllData:{},
                isIpad: false
            }
        },
        methods:{
            submit(){
                let year = this.data.start_day.getFullYear(),
                    mon = this.data.start_day.getMonth() + 1 < 10 ? this.data.start_day.getMonth() + 1 : this.data.start_day.getMonth() + 1,
                    day = this.data.start_day.getDate() < 10 ? '0' + this.data.start_day.getDate() : this.data.start_day.getDate(),
                    date = year + '-' + mon + '-' + day;
                if(this.getData && this.from != 'programme' && !this.DetailGetTemp){
                    this.isShow = false,
                    this.$emit("closePlan",this.isShow);
                }else{
                    this.detailsDelte(this.plan, date);
                }
                this.activeDate = 0
            },
            close(){
                this.loading = true,
                this.isShow = false,
                this.activeDate = 0,
                window.isCloseHint = false;
                this.tab = '0'
                this.ClearPlan();
                this.$emit("closePlan", this.isShow);
            },
            preventClose(){
                if(this.isShow){
                    window.isCloseHint = this.isShow;
                    //初始化关闭
                    window.addEventListener("beforeunload", function(e) {
                        if (window.isCloseHint) {
                            var confirmationMessage = "要记得保存哦！你确定要离开我吗？";
                            (e || window.event).returnValue = confirmationMessage; // 兼容 Gecko + IE
                            return confirmationMessage; // 兼容 Gecko + Webkit, Safari, Chrome
                        }
                        for(let i = 0; i < 7 ; i++ ){
                            removeItem('day' + i);
                        }
                    });
                }
            },
            selectTemp(id){
                this.DetailGetTemp = true;
                this.getPatientPlan(id,'temp',true);
            },
            templateGetList(){
                if(!this.getData){
                    templateGetList().then(res =>{
                        if(res.data.code == 100000){
                            this.templateList = res.data.data.list;
                        }
                    })
                }
            },
            getWeeklist(startDay,days){
                if( startDay != '' ){
                    switch (days) {
                        case '三天':
                            this.getWeek(3,startDay),
                            this.tempDays = 3,
                            this.data.plan = {
                                day1:[],
                                day2:[],
                                day3:[],
                            };
                        break;
                        case '七天':
                            this.getWeek(7,startDay),
                            this.tempDays = 7,
                            this.data.plan = {
                                day1:[],
                                day2:[],
                                day3:[],
                                day4:[],
                                day5:[],
                                day6:[],
                                day7:[],
                            };
                        break;
                        default:
                        break;
                    }
                }
                this.addParameter.startDay = startDay;
            },
            getWeek(days,startDay){
                let time = startDay.getTime(),
                    str = {};
                this.weekList = [];
                for( let i = 0 ; i < days ; i++ ){
                    let weekDayArr = ["周日","周一","周二","周三","周四","周五","周六"],//星期映射数组
                        myDate = time,
                        milliseconds = myDate+1000*60*60*24*i, //当i为0代表当前日期，为1时可以得到明天的日期，以此类推
                        newMyDate = new Date(milliseconds),
                        weekDay = newMyDate.getDay(), //获取当前星期X(0-6,0代表星期天)
                        // year = newMyDate.getFullYear(), //获取当前年
                        // month = newMyDate.getMonth()+1, //获取当前月
                        day = newMyDate.getDate() < 10 ? '0' + newMyDate.getDate() : newMyDate.getDate();//获取当前日
                    str = {
                        day: day,
                        week: weekDayArr[weekDay],
                    }
                    this.weekList.push(str);
                }
            },
            changeDate(index){
                setItem('day' + this.activeDate,JSON.stringify({
                    carbohydratesVal: this.carbohydratesVal,
                    insulinVal: this.insulinVal,
                    pillVal: this.pillVal,
                }));
                if(this.activeDate != index ){
                    this.carbohydratesVal = 0;
                    this.insulinVal = 0;
                    this.pillVal = 0;
                    this.activeDate = index;
                    this.addParameter.day = index + 1;
                }
            },
            showAdd(type) {
                this.addParameter = {};
                this.addParameter = {
                    show: true,
                    addType: type,
                    title: type == 1 ? '饮食' : (type ==2 ? '用药' : (type == 3 ? '胰岛素' : '运动' ) ),
                    startDay: this.timeCalc(this.data.start_day,this.activeDate),
                    day: this.activeDate,
                    from: this.from,
                    add: true,
                }
            },
            closeAdd(show){
                this.addParameter = {
                    show: show,
                    title: ''
                }
            },
            getPatientPlan(id,type){
                this.loading = true;
                this.insulinVal = 0,
                this.carbohydratesVal = 0,
                this.pillVal = 0,
                this.plan = {
                    day1:[],
                    day2:[],
                    day3:[],
                    day4:[],
                    day5:[],
                    day6:[],
                    day7:[],
                };
                let quest = type == 'temp' ? templateGetInfo({id}) : getUserPlan({uid: id});
                quest.then( res =>{
                    if(res.data.code == 100000 && JSON.stringify(res.data.data) != "{}"){
                        let date = this.from == 'details' && !this.DetailGetTemp ? new Date(res.data.data.start_date) : new Date();
                        this.data.title = res.data.data.title,
                        this.data.start_day = date
                        this.data.days = res.data.data.days == 3 ? '三天' : '七天',
                        this.getWeeklist( date ,this.data.days);
                        if(!this.DetailGetTemp){
                            this.getData = true,
                            this.total = res.data.data.plan['day' + ( this.activeDate + 1 ) ].total;
                        }else{
                            this.carbohydratesVal = res.data.data.plan['day' + ( this.activeDate + 1 ) ].total.carbohydrate,
                            this.calorie = res.data.data.plan['day' + ( this.activeDate + 1 ) ].total.calorie,
                            this.insulinVal = res.data.data.plan['day' + ( this.activeDate + 1 ) ].total.insulin,
                            this.pillVal = res.data.data.plan['day' + ( this.activeDate + 1 ) ].total.medicine;
                        }
                        this.getAllData = res.data.data.plan;
                        let plan = {},
                            getData = res.data.data.plan;
                        Object.keys(getData).forEach( (key) =>{
                            plan[key] = [];
                            if(getData[key].detail.length){
                                let data = getData[key].detail;
                                data.map( (item) => {
                                    let str = {},
                                        details = JSON.stringify(item.detail.map(items =>{
                                            return {
                                                title:items.title,
                                                num:items.num,
                                                unit_id:items.unit_id,
                                                [ item.kind == 1 ? 'food_id' : (item.kind == 2 ? 'medicine_id' : 'sports_id') ] : item.kind == 1 ? items.food_id : (item.kind == 2 ? items.medicine_id : items.sports_id )
                                            };
                                        })),
                                        day = parseInt(key.split('y')[1]),
                                        date = new Date(this.data.start_day),
                                        y = date.getFullYear(),
                                        m = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1),
                                        d = parseInt(this.weekList[ (day - 1)].day) < 10 ? '0' + parseInt(this.weekList[ (day - 1)].day) : this.weekList[ (day - 1)].day,
                                        time = y + '-' + m + '-' + d;
                                    str.id = item.id,
                                    str.kind = item.kind,
                                    str.type = item.type,
                                    str.source = this.from == 'programme' ? 3 : 2,
                                    str.title = item.plan_title,
                                    str.time_point = item.time_point,
                                    str.note = item.note,
                                    str.food_list = item.food_list;
                                    item.kind == 1 ? str.food_record_detail = details : item.kind == 2 ? str.medicine_record_detail = details : str.sports_record_detail = details,
                                    item.kind == 1 ? str.food_time = time : item.kind == 2 ? str.medicine_time = time : str.sports_time = time,
                                    str.details = item.detail,
                                    str.user_id = this.$route.query.user_id,
                                    str.doctor_id = item.doctor_id,
                                    str.time_interval = item.time_interval ? item.time_interval : '';
                                    plan[key].push(str);
                                })
                                this.loading = false;
                            }else{
                                this.loading = false;
                            }
                        })
                        this.plan = plan;
                    }else{
                        this.loading = false;
                    }
                })
            },
            planDetail(plan,type,show){
                this.from == 'programme' ? plan.relate_id = this.id : '';
                this.from == 'programme' ? plan.day = ( this.activeDate + 1) : '';
                delete plan.details;
                delete plan.food_list;
                if(type == 'add'){
                    this.num++;
                    this.addParameter = {
                        show: show,
                        title: ''
                    }
                }
                let quest = plan.kind == 1 ? foodRecordAdd(plan) : plan.kind == 2 ? medicineRecordAdd(plan) : sportsRecordAdd(plan);
                quest.then( res => {
                    if(res.data.code == 100000){
                        this.$message.success(res.data.msg);
                        this.getPatientPlan(this.id , this.from == 'programme' ? 'temp' : '');
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },
            addPlan(plan,index,show){
                this.addParameter = {};
                if( ( this.getData || this.DetailGetTemp ) ){
                    if(this.from == 'details' && !this.DetailGetTemp){
                        this.planDetail(plan,'add',show)
                    }else if(this.from == 'programme'){
                        this.planDetail(plan,'add',show)
                    }else{
                        this.plan['day' + (this.activeDate + 1)].push(plan)
                        this.num++;
                        this.addParameter = {
                            show: show,
                            title: ''
                        }
                    }
                }else{
                    this.plan['day' + Number(this.activeDate + 1)].push(plan)
                    this.num++;
                    this.addParameter = {
                        show: show,
                        title: ''
                    }
                }
                this.carbohydrates(this.showList,'add');
            },
            editPlan(plan,index,show){
                if( ( this.getData || this.DetailGetTemp ) ){
                    if(this.from == 'details' && !this.DetailGetTemp){
                        this.planDetail(plan,'edit',show)
                    }else if(this.from == 'programme'){
                        this.planDetail(plan,'edit',show)
                    }else{
                        this.plan['day' + (this.activeDate + 1)][index - 1] = plan;
                        this.addParameter = {
                            show: show,
                            title: ''
                        }
                    }
                }else{
                    this.plan['day' + (this.activeDate + 1)][index - 1] = plan;
                    this.addParameter = {
                        show: show,
                        title: ''
                    }
                }
                // this.carbohydrates(this.showList,'edit');
            },
            edit(plan,index){
                let type = plan.kind == 1 ? 1 : plan.kind == 2 && plan.type == 1 ? 2 : plan.kind == 2 && plan.type == 2 ? 3 : 4;
                this.addParameter = {};
                this.addParameter = {
                    show: true,
                    plan: plan,
                    index: index + 1,
                    startTime: this.timeCalc(this.data.start_day,this.activeDate),
                    day: this.activeDate + 1,
                    from: this.from,
                    title: plan.title,
                    add: false,
                }
                this.addParameter.type = type;
            },
            del(data){
                let getData = this.getData,
                    from = this.from,
                    kind = data.kind,
                    id = data.id,
                    index = data.index,
                    res,
                    DetailGetTemp = this.DetailGetTemp;

                this.showList.splice(index,1);
                // this.plan['day'+ ( this.activeDate + 1 )] = this.showList;
                this.loading = true;
                if(getData || DetailGetTemp){
                    if(this.from == 'details' && !DetailGetTemp){
                        res = kind == 1 ? foodRecordDel({id}) : ( kind == 2 ? medicineRecordDel({id}) : sportsRecordDel({id}) );
                        this.getPatientPlan(this.id, from == 'details' ? '' : 'temp');
                        setTimeout( () => {
                            if (res.data.code == 100000) {
                                this.num = this.showList.length;
                                this.$message.success(res.data.msg);
                                this.loading = false;
                            } else {
                                this.$message.error(res.data.msg);
                                this.loading = false;
                            }
                        },1000)
                    }else if(this.from == 'programme'){
                        res = kind == 1 ? foodRecordDel({id}) : ( kind == 2 ? medicineRecordDel({id}) : sportsRecordDel({id}) );
                        this.getPatientPlan(this.id, from == 'details' ? '' : 'temp');
                        setTimeout( () => {
                            if (res.data.code == 100000) {
                                this.num = this.showList.length;
                                this.$message.success(res.data.msg);
                                this.loading = false;
                            } else {
                                this.$message.error(res.data.msg);
                                this.loading = false;
                            }
                        },1000)
                    }else{
                        setTimeout( () => {
                            this.num = this.showList.length;
                            this.loading = false;
                        },400)
                    }
                }else{
                    setTimeout( () => {
                        this.num = this.showList.length;
                        this.loading = false;
                    },400)
                }
                this.carbohydrates(this.showList)
            },
            carbohydrates(plan){
                this.carbohydratesVal = 0,
                this.calorie = 0,
                this.insulinVal = 0,
                this.pillVal = 0;
                for(let i in plan){
                    let detail = plan[i].details || plan[i].detail,
                        defaultData = plan[i].food_list;
                    switch(plan[i].kind){
                        case 1 :
                            for(let j in detail){
                                if(this.DetailGetTemp){
                                    this.carbohydratesVal = this.carbohydratesVal + this.decimalPointIntercept(Number(( defaultData[j].carbohydrate * detail[j].num / 100 )));
                                    this.calorie = this.calorie + this.decimalPointIntercept(Number(( defaultData[j].calorie * detail[j].num / 100 )));
                                }else{
                                    this.carbohydratesVal = this.carbohydratesVal + this.decimalPointIntercept(Number(( detail[j].carbohydrate * detail[j].num / 100 )));
                                    this.calorie = this.calorie + this.decimalPointIntercept(Number(( detail[j].calorie / 100 ) * detail[j].num ));
                                }
                            }
                        break;
                        case 2:
                            if(plan[i].type == 2){
                                for(let j in detail){
                                    this.insulinVal = this.insulinVal + this.decimalPointIntercept(Number(detail[j].num));
                                }
                            }else{
                                this.pillVal = this.pillVal + 1
                            }
                        break;
                    }
                }
            },
            detailsDelte(data,date){
                let plan = {};
                Object.keys(data).forEach( (key) =>{
                    plan[key] = [];
                    if(data[key].length != 0){
                        let items = data[key];
                        items.map( (item) => {
                            delete item.details;
                            delete item.food_list;
                            plan[key].push(item);
                        })
                    }
                })
                this.savePlan(plan,date)
            },
            savePlan(plan,date){
                let data = {
                    title: this.data.title,
                    days: this.data.days == '七天' ? 7 : 3,
                    plan: plan,
                };
                this.id ? ( this.from == 'details' ? data.user_id = this.id : data.id = this.id ) : '',
                this.from == 'details' ? data.start_day = date : '';
                if(this.data.title == ''){
                    this.$message.warning('请输入方案标题');
                }else{
                    let quest = this.from == 'details' ? planSave(data) : planSaveTemp(data);
                    quest.then( res => {
                        if(res.data.code == 100000){
                            window.isCloseHint = false;
                            this.$message.success(res.data.msg);
                            this.ClearPlan();
                            setTimeout( () =>{
                                location.reload();
                            },1000)
                        }else{
                            window.isCloseHint = false;
                            this.$message.error(res.err);
                            this.ClearPlan();
                        }
                    })
                    this.isShow = false;
                    if(this.from == 'details'){
                        this.$emit("showModel",this.isShow);
                    }else if(this.from == 'programme'){
                        this.$emit("submitDate",this.isShow);
                    }
                }
            },
            ClearPlan(){
                this.getData = false;
                this.DetailGetTemp = false;
                this.plan = {
                day1:[],
                day2:[],
                day3:[],
                day4:[],
                day5:[],
                day6:[],
                day7:[],
                }
                this.data = {
                    title: '',
                    start_day: new Date(),
                    days: '七天',
                    plan:{}
                }
                this.tab = '0';
                this.showList = [];
                if(this.getTemp){
                    this.total.carbohydrate = 0,
                    this.total.insulin = 0,
                    this.total.medicine = 0,
                    this.getTemp = false;
                }else{
                    this.carbohydratesVal =0,
                    this.insulinVal = 0,
                    this.pillVal =  0;
                }
                for(let i = 0; i < 7 ; i++ ){
                    removeItem('day' + i);
                }
            },
            timeCalc(date,day){
                let time = date.getTime(),
                    newTime = new Date(time + 1000 * 60 * 60 * 24 * day ),
                    y = newTime.getFullYear(),
                    m = newTime.getMonth() + 1 < 10 ? '0' + ( newTime.getMonth() + 1 ) : newTime.getMonth() + 1,
                    d = newTime.getDate() < 10 ? '0' + newTime.getDate() : newTime.getDate();
                return y + '-' + m + '-' + d;
            },
            decimalPointIntercept(val){
                let isInteger = (val.toString()).indexOf(".");
                if(isInteger != -1){
                    let Integer = val.toString().split('.')[0],
                        Decimal = val.toString().split('.')[1];
                    if(Decimal.length > 2 ){
                        Decimal = Decimal.substring(0,2);
                    }
                    val = Number(Integer + '.' + Decimal);
                }else{
                    return val;
                }
                return val;
            },
            deletePlan(id){
                this.$confirm('删除后用户将无法看到今日以后的方案数据。你确定要删除该控糖方案？','提示',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    customClass: !this.Dark ? '' : 'darkDetailsDialogBox'
                }).then( () =>{
                    const quest = this.from == 'details' ? userPlanDelete({uid: id}) : doctorPlanDelete({id});
                    quest.then( res =>{
                        if(res.data.code == 100000){
                            this.$message.success('删除成功！');
                            this.ClearPlan();
                            this.close();
                            this.$emit('getTempList')
                        }else{
                            this.$message.warning('删除失败！请联系开发人员');
                        }
                    })
                })
            }
        },
        mounted(){
            this.isIpad = window.innerWidth >= 1024 && window.innerWidth <= 1366;
        }
    }
</script>

<style lang="less">


    .addPlanDialog .el-dialog__body{
        height: ~'calc(100% - 60px)';
        overflow: hidden;
        padding: 20px;

        .el-form{
            height: ~'calc(100% - 100px)';
        }
    }

    .addPlanDialog{
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        & > .el-dialog{
                width: 80%;
                height: 90%;
                border-radius: 10px;
                background: none;
                background: white;
                position: fixed;
                margin: 0px !important;

                .el-dialog__header{
                    border-bottom: 1px solid #dcdfe6;
                    padding: 14px 16px;
                    span{
                        font-size: 14px;
                        color: #17233d;
                    }
                    button{
                        margin-top: 4px;
                    }
                }

                .tabs{

                    .el-tabs__nav{
                        border: unset;
                        height: 35px;
                    }

                    .el-tabs__item{
                        position: absolute;
                        border-radius: 5px 5px 0px 0px;
                        height: 35px;
                        line-height: 35px;
                        background: #f8f8f9;
                        border: 1px solid #dcdee2;

                        &:nth-child(2){
                            left: 105px;
                            border-left: unset;
                            background: #f8f8f9;
                            border: 1px solid #dcdee2;
                        }
                    }
                    .el-tabs__item:hover{
                        color: var(--defalutColor);
                    }
                    
                    .el-tabs__item.is-active{
                        color: var(--defalutColor);
                        border: 1px solid var(--defalutColor);
                        border-bottom-color: var(--white);
                        background: none;
                    }
                }

                .content-info{
                    display: flex;
                    height: 100%;
                    overflow: hidden;

                    .left,
                    .right{
                        height: 100%;
                        padding-left: 20px;

                        &::before{
                            content: '';
                            position: absolute;
                            top: 0px;
                            right: 0px;
                            width: 1px;
                            height: 100%;
                            background: #f4f4f4;
                        }

                        .dropDown{
                            button{
                                padding: 0px;
                                width: 100px;
                                &>span{
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    span{
                                        display: block;
                                        font-size: 12px;
                                        font-weight: 400;
                                        overflow: hidden;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        padding: 10px;
                                        max-width: 60px;
                                        background: unset;
                                        transition: .3s;
                                        padding-right: 0px;
                                    }
                                }
                            }
                        }

                        .el-form-item__label{
                            font-size: 12px;
                        }

                        .el-input{
                            width: 150px;

                            input{
                                height: 32px;
                                font-size: 12px;

                                &::placeholder{
                                    font-size: 12px;
                                }
                            }
                        }
                        .selectPlan{
                            padding: 5px 15px 6px;
                            font-size: 12px;
                            border-radius: 4px;
                            line-height: 1.6;
                            font-weight: normal;
                            transition: .3s;
                            cursor: pointer;
                            background: var(--defalutColor);
                            border-color: var(--defalutColor);
                            color: white;
                            &:hover{
                                background: var(--defalutActiveColor);
                                border-color: var(--defalutActiveColor);
                                color: white;
                            }
                        }

                    }

                    .left{
                        width: 35%;
                    }

                    .right{
                        width: 65%;
                        height: 100%;
                        padding: 0px 20px;
                        position: relative;

                        &::before{
                            content: unset;
                        }

                        .weekList {
                            transition: all 0.3s;
                            padding-top: 0px;
                            padding-bottom: 10px;
                            display: flex;
                            flex-wrap: nowrap;
                            width: 100%;
                            overflow-x: scroll;

                            .list {
                                cursor: pointer;
                                width: 85px;
                                height: 40px;
                                background: #ffffff;
                                border-radius: 4px;
                                border: 1px solid #cecece;
                                margin-right: 12px;
                                position: relative;

                                .date {
                                    font-size: 12px;
                                    color: #aaaaaa;
                                    position: absolute;
                                    left: 15px;
                                    top: 10px;
                                    span{
                                        font-size: 13px;
                                        font-family: PingFangSC-Semibold, PingFang SC;
                                        font-weight: 600;
                                        color: #242424;
                                    }
                                }
                            }

                            .list.active {
                                background: #13be9b;
                                border: 0 none;

                                .date,
                                .week{
                                    color: #fff;
                                    span{
                                        color: white;
                                    }
                                }
                            }

                            .list.active::after {
                                display: block;
                                content: "";
                                border-width: 8px 8px 8px 8px;
                                border-style: solid;
                                border-color: #13be9b transparent transparent transparent;
                                position: absolute;
                                left: 50%;
                                transform: translateX(-50%);
                                top: 100%;
                            }

                            .list:last-child {
                                margin-right: 0;
                            }
                        }

                        .total{
                            margin-top: 5px;
                            display: flex;
                            justify-content: space-between;
                            background: #FAFAFA;
                            .example{
                                display: flex;
                                align-items: baseline;
                                h4{
                                    font-size: 15px;
                                    font-family: PingFang-SC-Heavy, PingFang-SC;
                                    font-weight: 500;
                                    color: #0d0e10;
                                }
                                p{                 
                                    font-size: 12px;
                                    color: #9f9f9f;
                                    span{
                                        font-size: 18px;
                                        font-family: PingFangSC-Medium, PingFang SC;
                                        font-weight: 500;
                                        color: #13be9b;
                                        margin-right: 4px;
                                        margin-top: -4px;
                                    }
                                }
                            }
                        }

                        .btnList{
                            display: flex;
                            margin-top: 10px;
                            justify-content: space-between;
                            .item {
                                cursor: pointer;
                                width: 120px;
                                height: 32px;
                                border-radius: 20px;
                                border: 1px solid #f0f0f0;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 12px;
                                font-family: PingFang-SC-Heavy, PingFang-SC;
                                font-weight: 800;
                                color: #242424;
                                img {
                                width: 18px;
                                height: 18px;
                                }
                            }
                        }
                        .addList{
                            height: ~'calc( 100% - 160px)';
                            min-height: 150px;
                            margin-top: 10px;
                            overflow-y: scroll;
                        }
                        .empty{
                            display: block;
                            margin-top: 120px;
                            text-align: center;
                            img{
                                width: 90px;
                            }
                            p{
                                font-size: 16px;
                                font-family: PingFangSC-Semibold, PingFang SC;
                                font-weight: 600;
                                color: #B8B8B8;
                            }
                        }
                    }
                }

                .el-dialog__footer{
                    border-top: 1px solid #dcdfe6;
                    padding-top: 15px;
                    position: absolute;
                    bottom: 0px;
                    width: 100%;

                    button{
                        padding: 5px 15px 6px;
                        font-size: 12px;
                        border-radius: 4px;
                        line-height: 1.6;
                        font-weight: normal;
                        transition: .3s;

                        span{
                            display: block;
                            padding-top: 2px;
                        }

                        &.delete{
                            position: absolute;
                            left: 20px;
                            background: #ff6e6e;
                            border-color: #ff6e6e;
                            color: white !important;
                            &:hover,
                            &:focus{
                                background: #eb3f3f;
                                border-color: #eb3f3f !important;
                            }
                        }

                        &.save{
                            transition: .3s;
                            background: var(--defalutColor);
                            border-color: var(--defalutColor);
                            color: white;

                            &:hover{
                                background-color: var(--defalutActiveColor);
                                border-color: var(--defalutActiveColor);
                                color: white !important;
                                transition: .3s;
                            }
                        }
                    }
                }
            }
    }

    .addPlanDialog.dark{
        .el-dialog,
        .el-dialog__header,
        .el-dialog__body,
        .el-dialog__footer{
            background: #202025 !important;
            border-color: #2c2c2e !important;
            .el-tabs__header{
                border-color: #2c2c2e !important;
            }
            .el-picker-panel{
                background: #202025;
                border-color: #0c0c0c;
                .el-picker-panel__icon-btn,
                .el-date-picker__header-label{
                    color: #c1c1c1;
                }
                .el-date-table th{
                    color: #c1c1c1;
                    border-color: #0c0c0c;
                }
                .prev-month span{
                    color: #c1c1c1;
                }
                .available{
                    span{
                        color: #fff;
                    }
                    &.current{
                        span{
                            background-color: var(--defalutActiveColor);
                        }
                    }
                }
                .normal.disabled div,
                .next-month.disabled div{
                    background: #0c0c0c;
                    color: #7f7f7f;
                }
                .popper__arrow{
                    border-bottom-color: #202025;
                    &::after{
                        border-bottom-color: #202025;
                    }
                }
            }
            .el-dropdown-menu{
                background-color: #202025;
                border-color: #0c0c0c;
                overflow: unset;
                li:hover{
                    color: #c1c1c1 !important;
                }
                /deep/ .popper__arrow{
                    border-bottom-color: #0c0c0c !important;
                    &::after{
                        border-bottom-color: #202025 !important;
                    }
                }
            }
            .el-select-dropdown{
                background-color: #202025;
                border-color: #0c0c0c;
                .hover{
                    background-color: #0c0c0c;
                }
                .el-select-dropdown__item{
                    color: #c1c1c1;
                }
                .popper__arrow{
                    border-bottom-color: #0c0c0c;
                    &::after{
                        border-bottom-color: #0c0c0c;
                    }
                }
            }
            .tabs{
                .el-tabs__item{
                    background: #0d0e10 !important;
                    border-color: #2c2c2e !important;
                    color: #c1c1c1 !important;

                    &.is-active{
                        color: var(--defalutColor) !important;
                        border: 1px solid var(--defalutColor) !important;
                        border-bottom-color: #2c2c2e !important;
                        background: unset !important;
                    }
                }
            }

            .left{
                &::before{
                    background: #2c2c2e;
                }
            }

            .el-dialog__title,
            .el-dialog__close{
                color: #c1c1c1;
            }
            .el-form-item__label{
                color: #c1c1c1 !important;
            }
            .el-input__inner{
                background-color: unset;
                color: #c1c1c1;
                border-color: #2c2c2e !important;
                &::placeholder{
                    color: #c1c1c1 !important;
                    border-color: #2c2c2e !important;
                }
            }
            .weekList{
                .list{
                    background: #0d0e10 !important;
                    border-color: #2c2c2e !important;
                    .date,
                    .week{
                        color: #c1c1c1 !important;
                        span{
                            color: #c1c1c1 !important;
                        }
                    }
                    &.active{
                        background: #13be9b !important;
                        .date,
                        .week{
                            color: white !important;
                            span{
                                color: white !important;
                            }
                        }
                    }
                }
            }
            .total{
                background: #0d0e10 !important;
                h4{
                    color: #c1c1c1 !important;
                }
                p{
                    color: #c1c1c1 !important;
                }
            }
            .el-form-item__content{
                .el-input-group__append{
                    background-color: #0c0c0c !important;
                    color: #c1c1c1 !important;
                    border-color: #2c2c2e !important;
                }
            }
            .btnList{
                .item{
                    border-color: #2c2c2e !important;
                }
                span{
                    color: #c1c1c1 !important;
                }
            }
            .el-button{
                background-color: unset;
                color: #c1c1c1;
                border-color: #2c2c2e;
            }
            .dialog-footer{

                button:nth-of-type(3){
                    background: var(--defalutActiveColor) !important;
                    border-color: var(--defalutActiveColor) !important;
                }
            }
        }

    }

    @media screen{
        @media (max-width: 1024px) and (min-width: 768px) {
            .addPlanDialog{
                overflow: hidden;
                & > .el-dialog{
                    width: 90%;
                    height: 80%;
                    border-radius: 10px;
                    background: none;
                    background: white;
                }
            }

            .addPlanDialog{
                overflow: hidden;
                & > .el-dialog{
                    .addList{
                        height: ~'calc(100% - 160px)' !important;
                    }
                }
            }
        }
    }

    .darkDetailsDialogBox{
        background-color: #0c0c0c;
        border-color: #0c0c0c;
        .el-message-box__title,
        .el-message-box__message{
            color: #c1c1c1;
        }
        .el-message-box__btns{
            .el-button:nth-child(1){
                background-color: #202025;
                border-color: #202025;
                color: #c1c1c1;
                &:hover{
                    color: var(--defalutColor) !important;
                }
            }
        }
    }

</style>