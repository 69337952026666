<template>
    <div class="reversePlan b-pad-30" :class="[!Dark ? '' : 'dark']" v-loading="!loading">
        <!-- <div class="target" v-if="planID == '' || planID <= 0">
            <h4>逆转目标配置</h4>
            <el-button class="radius-50 mt-20 button" @click="showAddPlan = !showAddPlan">添加逆转目标</el-button>
        </div> -->
        <div class="content" v-if="planID != ''">
            <div class="heads flex justify-between">
                <div class="title flex">
                    <h2>逆转方案 <span class="over">{{ UserReversePlan.status == -1 ? '（已完成方案）' : ''}}</span></h2>
                </div>
                <div class="btn">
                    <el-button class="addDrug radius-50" @click="showPrescription = !showPrescription" v-if="UserReversePlan.status != -1 ">添加处方</el-button>
                    <el-button class="add radius-50" @click="showStageAdd" v-if="UserReversePlan.status != -1 ">添加阶段内容</el-button>
                    <el-button class="add radius-50" @click="showAddPlan = !showAddPlan" v-if="UserReversePlan.status == -1 ">添加逆转方案</el-button>
                    <el-button class="addSum radius-50" @click="planOver = !planOver">方案总结</el-button>
                </div>
            </div>
            <p class="flex align-center tips font-12 pb-10 solid-bottom mt-10">{{ UserReversePlan.intro }}</p>
            <div class="target mt-15 flex justify-between">
                <div class="title flex align-end">
                    <h4 class="font-20 font-weight">逆转目标</h4>
                    <p class="ml-15 font-15">血糖达标率：<span>{{ UserReversePlan.target_bs_ratio }}%</span></p>
                </div>
                <el-button class="edit radius-50" @click="plan(UserReversePlan.id,UserReversePlan)" v-if="UserReversePlan.status != -1 ">编辑</el-button>
            </div>
            <div class="flex justify-around title mt-25">
                <h5 class="font-16 text-center">指标</h5>
                <h5 class="font-16 text-center">当前</h5>
                <h5 class="font-16 text-center">目标</h5>
            </div>
            <div class="list solid flex align-center justify-around pt-15 pb-15 radius-10 text-center mt-20">
                <div class="name">
                    <img src="@/assets/images/Patient/Details/icon03.png" alt="">
                    <p class="font-16">糖化</p>
                </div>
                <div class="present">
                    <h2 class="font-25 font-weight" :class="[ Number(UserReversePlan.start_hba1c) <= 4 ? 'low' : Number(UserReversePlan.start_hba1c) >= 6 ? 'high' : 'normal' ]">{{ Number(UserReversePlan.start_hba1c) }}</h2>
                    <p>%</p>
                    <div class="bar flex mt-10">
                        <div class="pion-rel" :class="[Number(UserReversePlan.start_hba1c) < 4 ? 'lone' : '' ]">
                            <img class="pion-abs" v-if="Number(UserReversePlan.start_hba1c) < 4" src="@/assets/images/Patient/Details/icon06.png" alt="">
                        </div>
                        <div class="ml-5 pion-rel" :class="[Number(UserReversePlan.start_hba1c) >= 4 && Number(UserReversePlan.start_hba1c) <= 6 ? 'lone' : '' ]">
                            <img class="pion-abs" v-if="Number(UserReversePlan.start_hba1c) > 4 && Number(UserReversePlan.start_hba1c) < 6" src="@/assets/images/Patient/Details/icon06.png" alt="">
                        </div>
                        <div class="ml-5 pion-rel" :class="[ Number(UserReversePlan.start_hba1c) > 6 ? 'lone' : '' ]">
                            <img class="pion-abs" v-if="Number(UserReversePlan.start_hba1c) > 6" src="@/assets/images/Patient/Details/icon06.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="target">
                    <h2 class="font-25 font-weight" :class="[ Number(UserReversePlan.target_hba1c) < 4 ? 'low' : Number(UserReversePlan.target_hba1c) > 6 ? 'high' : 'normal' ]">{{ Number(UserReversePlan.target_hba1c) }}</h2>
                    <p>%</p>
                    <div class="bar flex mt-10">
                        <div class="pion-rel" :class="[Number(UserReversePlan.target_hba1c) < 4 ? 'lone' : '' ]">
                            <img class="pion-abs" v-if="Number(UserReversePlan.target_hba1c) < 4" src="@/assets/images/Patient/Details/icon06.png" alt="">
                        </div>
                        <div class="ml-5 pion-rel" :class="[Number(UserReversePlan.target_hba1c) >= 4 && Number(UserReversePlan.target_hba1c) <= 6 ? 'lone' : '' ]">
                            <img class="pion-abs" v-if="Number(UserReversePlan.target_hba1c) >= 4 && Number(UserReversePlan.target_hba1c) <= 6" src="@/assets/images/Patient/Details/icon06.png" alt="">
                        </div>
                        <div class="ml-5 pion-rel" :class="[ Number(UserReversePlan.target_hba1c) >  6 ? 'lone' : '' ]">
                            <img class="pion-abs" v-if="Number(UserReversePlan.target_hba1c) > 6" src="@/assets/images/Patient/Details/icon06.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="list solid flex align-center justify-around pt-15 pb-15 radius-10 text-center mt-20">
                <div class="name">
                    <img src="@/assets/images/Patient/Details/icon04.png" alt="">
                    <p class="font-16">BMI</p>
                </div>
                <div class="present">
                    <h2 class="font-25 font-weight" :class="[ Number(UserReversePlan.start_bmi) < 18.5 ? 'high' : Number(UserReversePlan.start_bmi) > 23.9 ? 'low' : 'normal' ]">{{ Number(UserReversePlan.start_bmi) }}</h2>
                    <p>kg/m²</p>
                    <div class="bar flex mt-10">
                        <div class="pion-rel" :class="[Number(UserReversePlan.start_bmi) > 23.9 ? 'lone' : '' ]">
                            <img class="pion-abs" v-if="Number(UserReversePlan.start_bmi) > 23.9" src="@/assets/images/Patient/Details/icon06.png" alt="">
                        </div>
                        <div class="ml-5 pion-rel" :class="[Number(UserReversePlan.start_bmi) >= 18.5 && Number(UserReversePlan.start_bmi) <= 23.9 ? 'lone' : '' ]">
                            <img class="pion-abs" v-if="Number(UserReversePlan.start_bmi) >= 18.5 && Number(UserReversePlan.start_bmi) < 23.9" src="@/assets/images/Patient/Details/icon06.png" alt="">
                        </div>
                        <div class="ml-5 pion-rel" :class="[ Number(UserReversePlan.start_bmi) < 18.5 ? 'lone' : '' ]">
                            <img class="pion-abs" v-if="Number(UserReversePlan.start_bmi) < 18.5" src="@/assets/images/Patient/Details/icon06.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="target">
                    <h2 class="font-25 font-weight" :class="[ Number(UserReversePlan.target_bmi) < 18.5 ? 'high' : Number(UserReversePlan.target_bmi) > 23.9 ? 'low' : 'normal' ]">{{ Number(UserReversePlan.target_bmi) }}</h2>
                    <p>kg/m²</p>
                    <div class="bar flex mt-10">
                        <div class="pion-rel" :class="[Number(UserReversePlan.target_bmi) > 23.9 ? 'lone' : '' ]">
                            <img class="pion-abs" v-if="Number(UserReversePlan.target_bmi) > 23.9" src="@/assets/images/Patient/Details/icon06.png" alt="">
                        </div>
                        <div class="ml-5 pion-rel" :class="[Number(UserReversePlan.target_bmi) >= 18.5 && Number(UserReversePlan.target_bmi) <= 23.9 ? 'lone' : '' ]">
                            <img class="pion-abs" v-if="Number(UserReversePlan.target_bmi) >= 18.5 && Number(UserReversePlan.target_bmi) < 23.9" src="@/assets/images/Patient/Details/icon06.png" alt="">
                        </div>
                        <div class="ml-5 pion-rel" :class="[ Number(UserReversePlan.target_bmi) < 18.5 ? 'lone' : '' ]">
                            <img class="pion-abs" v-if="Number(UserReversePlan.target_bmi) < 18.5" src="@/assets/images/Patient/Details/icon06.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="list solid flex align-center justify-around pt-15 pb-15 radius-10 text-center mt-20">
                <div class="name">
                    <img src="@/assets/images/Patient/Details/icon05.png" alt="">
                    <p class="font-16">每日用药</p>
                </div>
                <div class="present">
                    <div class="medicationList" v-for="(item,idx) in UserReversePlan.medicine_record" :key="idx">
                        <h4 class="font-12">{{ item.type == '1' ? '口服药' : '胰岛素' }}</h4>
                        <p class="font-12">{{ item.medicine_title }}</p>
                        <h1 class="font-weight">{{ item.medicine_num }}<span class="font-normal font-14">{{ item.unit_title }}</span></h1>
                    </div>
                    <div class="insulinList" v-for="(item,idx) in UserReversePlan.insulin_record" :key="idx">
                        <h4 class="font-12">{{ item.type == '1' ? '口服药' : '胰岛素' }}</h4>
                        <p class="font-12">{{ item.medicine_title }}</p>
                        <h1 class="font-weight">{{ item.medicine_num }}<span class="font-normal font-14">{{ item.unit_title }}</span></h1>
                    </div>
                </div>
                <div class="target">
                    <div class="text-center" :class="{'mt-10' : idx > 0 }" v-for="(item,idx) in UserReversePlan.medicine_record" :key="idx">
                        <p class="font-12">口服药减药比例</p>
                        <h1 class="font-25 font-weight flex justify-center">{{ Number(item.reduce_ratio) }} <span class="font-12 font-normal block">%</span></h1>
                    </div>
                    <div class="text-center mt-20" v-for="(item,idx) in UserReversePlan.insulin_record" :key="idx">
                        <p class="font-12">胰岛素减药比例</p>
                        <h1 class="font-25 font-weight flex justify-center">{{ Number(item.reduce_ratio) }} <span class="font-12 font-normal block">%</span></h1>
                    </div>
                </div>
            </div>
            <div class="stageForList mt-25" v-for="(item,idx) in UserReversePlan.period_list" :key="idx">
                <div class="tit flex justify-between">
                    <div class="title flex">
                        <h1 class="font-20 font-weight">{{ item.title }}</h1>
                        <h4 class="mt-5 ml-15">{{ item.sub_title }}</h4>
                    </div>
                    <div class="edit flex align-center">
                        <div class="stageType flex align-center mr-15">
                            <span class="block radius-50" :class="[item.stageType == '进行中' ? 'ing' : item.stageType == '未开始' ? 'noStart' : 'end' ]"></span>
                            <p class="font-12 ml-5">{{ item.stageType }}</p>
                        </div>
                        <el-button class="font-normal font-14 radius-50 doctor" v-if="item.stageType != '未开始' && UserReversePlan.status != -1 " @click="doctorNote(item.id)">医生点评</el-button>
                        <el-button class="font-normal font-14 radius-50" @click="editPlan(item.id,item)" :disabled="item.stageType == '已结束'" v-if="UserReversePlan.status != -1 ">编辑</el-button>
                    </div>
                </div>
                <div class="info solid flex justify-around pt-20 pb-15 mt-25 radius-10">
                    <div class="info1 infos text-center">
                        <div class="title pt-5 pb-5 pl-15 pr-15 font-14 font-weight radius-2">
                            {{ item.period_one_title }}
                        </div>
                        <div class="label mt-10" v-for="(items,idxs) in item.period_one_content_arr" :key="idxs">
                            <p class="font-12 line-height-20">{{ items }}</p>
                        </div>
                    </div>
                    <div class="info2 infos text-center">
                        <div class="title pt-5 pb-5 pl-15 pr-15 font-14 font-weight radius-2">
                            {{ item.period_two_title }}
                        </div>
                        <div class="label mt-10" v-for="(items,idxs) in item.period_two_content_arr" :key="idxs">
                            <p class="font-12 line-height-20">{{ items }}</p>
                        </div>
                    </div>
                    <div class="date infos text-center">
                        <div class="title pt-5 pb-5 pl-15 pr-15 font-14 font-weight radius-2">
                            调理时间
                        </div>
                        <div class="date mt-10">
                            <p class="font-12 line-height-20">{{ timeMonth(item.start_time) }}</p>
                            <span class="font-12">|</span>
                            <p class="font-12 line-height-20">{{ timeMonth(item.end_time) }}</p>
                        </div>
                    </div>
                </div>
                <!-- <div class="foodPlan solid align-center pt-5 pb-5 pl-15 pr-15 mt-15 flex">
                    <img src="@/assets/images/Patient/Details/icon07.png" alt="">
                    <h4 class="font-12 ml-10">饮食方案</h4>
                    <p class="font-12">查看详情></p>
                </div> -->
                <div class="foodList flex justify-around mt-15 solid-bottom pt-20 pb-20 radius-5 mt-10" v-if="item.stageType != '未开始'">
                    <div class="list">
                        <p class="font-12 flex align-center justify-center">
                            糖化下降<span>{{ item.hba1c_reduce }}%</span>
                            <img class="ml-5" v-if="item.hba1c_reduce > 0" src="@/assets/images/Patient/Details/icon08.png" alt="">
                            <img class="ml-5" v-if="item.hba1c_reduce < 0" src="@/assets/images/Patient/Details/icon09.png" alt="">
                        </p>
                        <div class="num flex align-center mt-15">
                            <div class="start text-center">
                                <p class="font-18 font-weight">{{ UserReversePlan.start_hba1c }}</p>
                                <span class="font-12">%</span>
                            </div>
                            <span class="block ml-20 mr-20 radius-5"></span>
                            <div class="down text-center">
                                <p class="font-18 font-weight">{{ item.hba1c }}</p>
                                <span class="font-12">%</span>
                            </div>
                        </div>
                    </div>
                    <div class="list text-center">
                        <p class="font-12 flex align-center justify-center">
                            BMI下降<span>{{ item.bmi_reduce }}</span>
                            <img class="ml-5" v-if="item.bmi_reduce > 0" src="@/assets/images/Patient/Details/icon08.png" alt="">
                            <img class="ml-5" v-if="item.bmi_reduce < 0" src="@/assets/images/Patient/Details/icon09.png" alt="">
                        </p>
                        <div class="num flex align-center mt-15">
                            <div class="start text-center">
                                <p class="font-18 font-weight">{{ UserReversePlan.start_bmi }}</p>
                                <span class="font-12">kg/m²</span>
                            </div>
                            <span class="block ml-20 mr-20 radius-5"></span>
                            <div class="down text-center">
                                <p class="font-18 font-weight">{{ item.bmi }}</p>
                                <span class="font-12">kg/m²</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="medication" v-if="item.stageType != '未开始'">
                    <div class="list flex justify-around mt-15" v-for="(items, idxs) in item.reduce_medicine_record" :key="idxs">
                        <div class="name">
                            <p class="title font-12 text-center">
                                {{ items.medicine_title }}
                            </p>
                            <div class="num flex justify-center align-center mt-10">
                                <div class="start font-weight">
                                    {{ items.start_medicine_num }} <span class="font-normal font-12 mt-10">{{ items.unit_title}}</span>
                                </div>
                                <span class="block ml-20 mr-20 radius-5"></span>
                                <div class="end font-weight">
                                    {{ items.medicine_num }} <span class="font-normal font-12 mt-10">{{ items.unit_title}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="scale text-center">
                            <p class="title font-12">减药比例</p>
                            <h1 class="font-weight mt-10" :class="{'high' : items.reduce_ratio < 0}">{{ items.reduce_ratio }} <span class="font-12 font-normal mt-10">%</span></h1>
                        </div>
                    </div>
                    <div class="list flex justify-around mt-15" v-for="(items, idxs) in item.reduce_insulin_record" :key="idxs">
                        <div class="name">
                            <p class="title font-12 text-center">
                                {{ items.medicine_title }}
                            </p>
                            <div class="num flex justify-center align-center mt-10">
                                <div class="start font-weight">
                                    {{ items.start_medicine_num }} <span class="font-normal font-12 mt-10">{{ items.unit_title}}</span>
                                </div>
                                <span class="block ml-20 mr-20 radius-5"></span>
                                <div class="end font-weight">
                                    {{ items.medicine_num }} <span class="font-normal font-12 mt-10">{{ items.unit_title}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="scale text-center">
                            <p class="title font-12">减药比例</p>
                            <h1 class="font-weight mt-10" :class="{'high' : items.reduce_ratio < 0}">{{ items.reduce_ratio }} <span class="font-12 font-normal mt-10">%</span></h1>
                        </div>
                    </div>
                </div>
                <div class="doctorNote solid mt-20 pt-20 pb-20 pl-20 pr-20 radius-10" v-if="item.note != ''">
                    <h4 class="font-weight">医生点评：</h4>
                    <p>{{ item.note }}</p>
                </div>
            </div>
        </div>
        <div class="flex justify-center align-center notData" v-else>
            <div class="center-box">
                <img src="@/assets/images/error/notdata.png" alt />
                <h3 class="text-center">暂无数据</h3>
                <el-button class="radius-50 mt-20 button block auto" @click="showAddPlan = !showAddPlan">添加逆转方案</el-button>
            </div>
        </div>
        <div class="planAdd">
            <el-dialog width="800" :visible.sync="showAddPlan" :modal-append-to-body="false" top="10vh" :before-close="close">
                <div class="title">
                    <p class="font-weight">逆转方案</p>
                </div>
                <div class="planInfo">
                    <el-form ref="plan">
                        <el-form-item prop="intro">
                            <h4 class="tip font-weight font-16">方案简介</h4>
                            <el-input class="mt-0" type="textarea" :rows="3" v-model="synopsis" placeholder="请输入简介"></el-input>
                        </el-form-item>
                        <div class="form pt-5">
                            <p class="font-16 mt-10 font-weight">逆转目标设置</p>
                            <div class="form-item pt-20">
                                <div class="input flex align-center">
                                    <p class="title text-right">血糖达标率</p>
                                    <el-input class="inputs ml-10" v-model="accurate">
                                        <span slot="suffix">%</span>
                                    </el-input>
                                </div>
                                <div class="double flex justify-between mt-20 pt-10">
                                    <div class="input flex align-center">
                                        <p class="title text-right">当前糖化</p>
                                        <el-form-item prop="start_hba1c">
                                            <el-input class="inputs ml-10" v-model="hemoglobin">
                                                <span slot="suffix">%</span>
                                            </el-input>
                                        </el-form-item>
                                    </div>
                                    <div class="input flex align-center">
                                        <p class="title text-right">目标糖化</p>
                                        <el-form-item prop="target_hba1c">
                                            <el-input class="inputs ml-10" v-model="target">
                                                <span slot="suffix">%</span>
                                            </el-input>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div class="double flex justify-between mt-20 pt-10">
                                    <div class="input flex align-center">
                                        <p class="title text-right">当前BMI</p>
                                        <el-form-item prop="start_bmi">
                                            <el-input class="inputs ml-10" v-model="BMI">
                                                <span slot="suffix">kg/m²</span>
                                            </el-input>
                                        </el-form-item>
                                    </div>
                                    <div class="input flex align-center">
                                        <p class="title text-right">目标BMI</p>
                                        <el-form-item prop="target_bmi">
                                            <el-input class="inputs ml-10" v-model="BMI_Target">
                                                <span slot="suffix">kg/m²</span>
                                            </el-input>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div class="drug">
                                    <div class="medication">
                                        <div class="triple flex justify-between mt-20 pt-10 pion-rel" v-for="(item,idx) in medicationList" :key="idx">
                                            <div class="input flex align-center">
                                                <p class="title text-right">口服药名称</p>
                                                <el-form-item prop="medication">
                                                    <el-autocomplete class="inputs ml-10" :popper-append-to-body="false" :fetch-suggestions="(queryString,cb)=>{watchName(queryString,cb,idx,1)}" v-model="item.title" placeholder="请输入内容" @select="(item) => {searchDrug(item,idx,0)}">
                                                        <template slot-scope="{ item }">
                                                            <div>{{ item.value = item.title }}</div>
                                                        </template>
                                                    </el-autocomplete>
                                                </el-form-item>
                                            </div>
                                            <div class="input flex align-center">
                                                <p class="title text-right">剂量</p>
                                                <el-form-item prop="num">
                                                    <el-input class="inputs ml-10" v-model="item.num">
                                                        <template slot="append">
                                                            <el-dropdown @command="(command) =>{changeUnit(command,idx,0)}">
                                                                <span class="el-dropdown-link">
                                                                    {{ item.unit_list.length == 0 ? '片' : (isNaN(item.unit) ? item.unit : item.unit_list[Number(item.unit) - 1].title) }}/天
                                                                </span>
                                                                <el-dropdown-menu slot="dropdown" :append-to-body="false">
                                                                    <el-dropdown-item v-for="(items,idxs) in item.unit_list" :command="idxs" :key="idxs">{{items.title}}</el-dropdown-item>
                                                                </el-dropdown-menu>
                                                            </el-dropdown>
                                                        </template>
                                                    </el-input>
                                                </el-form-item>
                                            </div>
                                            <div class="input flex align-center">
                                                <p class="title text-right">减药比例</p>
                                                <el-form-item prop="rate">
                                                    <el-input class="inputs ml-10" v-model="item.reduce_ratio">
                                                        <span slot="suffix">%</span>
                                                    </el-input>
                                                </el-form-item>
                                            </div>
                                            <i class="el-icon-remove-outline pion-abs" @click="deleteItem(idx,0)"></i>
                                        </div>
                                        <el-button class="radius-50 mt-20 addMedication" size="small" @click="addMedication(0)">添加口服药</el-button>
                                    </div>
                                    <div class="insulin">
                                        <div class="triple flex justify-between mt-20 pt-10 pion-rel" v-for="(item,idx) in insulinList" :key="idx">
                                            <div class="input flex align-center">
                                                <p class="title text-right">胰岛素名称</p>
                                                <el-form-item prop="insulin">
                                                    <el-autocomplete class="inputs ml-10" :popper-append-to-body="false" :fetch-suggestions="(queryString,cb)=>{watchName(queryString,cb,idx,2)}" v-model="item.title" placeholder="请输入内容" @select="(item) => {searchDrug(item,idx,1)}">
                                                        <template slot-scope="{ item }">
                                                            <div>{{ item.value = item.title }}</div>
                                                        </template>
                                                    </el-autocomplete>
                                                </el-form-item>
                                            </div>
                                            <div class="input flex align-center">
                                                <p class="title text-right">剂量</p>
                                                <el-form-item prop="insulin_num">
                                                    <el-input class="inputs ml-10" v-model="item.num">
                                                        <template slot="append">
                                                            <el-dropdown @command="changeUnit(idx,idxs,1)">
                                                                <span class="el-dropdown-link">
                                                                    {{ item.unit_list.length == 0 ? 'U' : (isNaN(item.unit) ? item.unit : item.unit_list[Number(item.unit) - 1].title) }}/天
                                                                </span>
                                                                <el-dropdown-menu slot="dropdown" :append-to-body="false">
                                                                    <el-dropdown-item v-for="(items,idxs) in item.unit_list" :key="idxs">{{items.title}}</el-dropdown-item>
                                                                </el-dropdown-menu>
                                                            </el-dropdown>
                                                        </template>
                                                    </el-input>
                                                </el-form-item>
                                            </div>
                                            <div class="input flex align-center">
                                                <p class="title text-right">减药比例</p>
                                                <el-form-item prop="insulin_rate">
                                                    <el-input class="inputs ml-10" v-model="item.reduce_ratio">
                                                        <span slot="suffix">%</span>
                                                    </el-input>
                                                </el-form-item>
                                            </div>
                                            <i class="el-icon-remove-outline pion-abs" @click="deleteItem(idx,1)"></i>
                                        </div>
                                        <el-button class="radius-50 mt-20 addMedication" size="small" @click="addMedication(1)">添加胰岛素</el-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-form>
                    <el-button class="submit auto radius-50 block mt-20 pt-10" @click="submit">保存</el-button>
                </div>
            </el-dialog>
        </div>
        <div class="addStage">
            <el-dialog width="800" :visible.sync="showStage" :modal-append-to-body="false" top="15vh" :before-close="closes">
                <div class="title">
                    <p class="font-weight">阶段内容</p>
                </div>
                <el-form :model="stage" :rules="stageRule" ref="stage">
                    <el-form-item label="阶段主题" prop="title" label-width="80px">
                        <div class="flex inputs">
                            <el-input v-model="stage.title" maxlength="10"></el-input>
                            <span class="ml-10">最多10个字</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="副标题" prop="subTitle" label-width="80px">
                        <div class="flex inputs">
                            <el-input v-model="stage.subTitle" maxlength="10"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="调理时间" prop="times" label-width="80px">
                        <div class="flex inputs times align-center">
                            <el-input v-model="stage.times"></el-input>
                            <el-button class="ml-10 pion-rel btn selectDays">
                                <img class="" src="@/assets/images/Patient/Details/icon01.png" alt="">
                                <el-date-picker class="pion-abs days" v-model="mon" @change="selectTimes(mon)" :append-to-body="false" type="daterange"></el-date-picker>
                            </el-button>
                        </div>
                    </el-form-item>
                    <p class="font-12 tips mb-20">
                        子标题长度最多4字，子标题内容用逗号分割，最多6字&nbsp;&nbsp;<span>例如：血糖平稳，BMI下降，控制餐后血糖</span>
                    </p>
                    <div class="double flex">
                        <el-form-item label="子标题1" prop="titleChildOne" label-width="80px">
                            <el-input class="lit" v-model="stage.titleChildOne"></el-input>
                        </el-form-item>
                        <el-form-item class="ml-20" label="子标题1内容" prop="titleChildDetails" label-width="120px">
                            <el-input v-model="stage.titleChildDetails"></el-input>
                        </el-form-item>
                    </div>
                    <div class="double flex">
                        <el-form-item label="子标题2" prop="titleChildOne" label-width="80px">
                            <el-input class="lit" v-model="stage.titleChildTwo"></el-input>
                        </el-form-item>
                        <el-form-item class="ml-20" label="子标题2内容" prop="titleChildTwoDetails" label-width="120px">
                            <el-input v-model="stage.titleChildTwoDetails"></el-input>
                        </el-form-item>
                    </div>
                    <el-form-item label-width="0px" class="button">
                        <el-button class="submit auto radius-50 block mt-20 pt-10" @click="submitReverse('stage')">保存</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
        </div>
        <AddDrug :showPrescription="showPrescription" :id="patientInfo.id" @drugClose="drugClose" @upData="upData" />
        <div class="addNote">
            <el-dialog width="800" :visible.sync="showNote" :modal-append-to-body="false" top="15vh" :before-close="cNote">
                <div class="title">
                    <p class="font-weight">医生点评</p>
                </div>
                <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 8}" placeholder="请输入内容" v-model="note" />
                <el-button class="submit auto radius-50 block mt-20 pt-10" @click="submitNote(note)">保存</el-button>
            </el-dialog>
        </div>
        <div class="addPlanSum">
            <el-dialog width="800" :visible.sync="planOver" :modal-append-to-body="false" top="15vh" :before-close="planOverClose">
                <div class="title">
                    <p class="font-weight">添加方案总结</p>
                    <span class="font-14">说明：此内容仅在方案结束后向用户展示，每种结语不超过一百字</span>
                </div>
                <p class="mt-20">医生结语：</p>
                <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 8}" placeholder="请输入医生结语" v-model="doctor_words" />
                <p class="mt-15">营养师结语：</p>
                <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 8}" placeholder="请输入营养师结语" v-model="dietitian_words " />
                <p class="mt-15">健管师结语：</p>
                <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 8}" placeholder="请输入健管师结语" v-model="wellness_words" />
                <el-button class="submit auto radius-50 block mt-20 pt-10" @click="submitPlanOver">保存</el-button>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import { time } from '_js/Cache';
    import {medicineSearch, reversePlanSave, reversePlanPeriodSave, periodNoteSave, planConclusionSave} from 'quest';
    import { AddDrug } from '_c/Model/index';
    export default {
        props:{
            patientInfo: Object,
            Dark: Boolean,
            UserReversePlan: Object
        },
        components:{
            AddDrug
        },
        watch:{
            UserReversePlan:{
                deep: true,
                handler(val){
                    if(val != null && val != 'null'){
                        val.period_list.map( item =>{
                            item.stageType = this.returnStageType(item.start_time,item.end_time)
                        })
                        this.status = val.status;
                        // val.status == '-1' || val.id == '' ? '' : 
                        this.planID = val.id;
                        this.doctor_words = val.doctor_words;
                        this.dietitian_words = val.dietitian_words;
                        this.wellness_words = val.wellness_words;
                        this.loading = true;
                    }
                }
            }
        },
        data(){
            return{
                loading: false,
                showAddPlan: false,
                showStage: false,
                showNote: false,
                addTitle: '第一阶段',
                synopsis: '',
                accurate:'',
                hemoglobin: '',
                target: '',
                planID: '',
                status: '',
                stageID:'',
                BMI: '',
                BMI_Target: '',
                medicationList:[
                    {
                        title:'',
                        num:'',
                        reduce_ratio :'',
                        unit_list:[]
                    }
                ],
                insulinList:[
                    {
                        title:'',
                        num:'',
                        reduce_ratio :'',
                        unit_list:[]
                    }
                ],
                stage:{
                    title: '',
                    subTitle: '',
                    times:'',
                    startTimestamp: '',
                    endTimestamp: '',
                    titleChildOne: '',
                    titleChildDetails:'',
                    titleChildTwo: '',
                    titleChildTwoDetails: ''

                },
                mon: new Date(),
                stageRule:{
                    title: [
                        { required: true, message: '请输入主题', trigger: 'blur' },
                    ],
                    subTitle: [
                        { required: true, message: '请输入副标题', trigger: 'blur' },
                    ],
                    times: [
                        { required: true, message: '请输入或选择时间', trigger: 'blur' },
                    ],
                    titleChildOne: [
                        { required: true, message: '请输入子标题1', trigger: 'blur' },
                    ],
                    titleChildDetails: [
                        { required: true, message: '请输入子标题1内容', trigger: 'blur' },
                    ],
                    titleChildTwo: [
                        { required: true, message: '请输入子标题2', trigger: 'blur' },
                    ],
                    titleChildTwoDetails: [
                        { required: true, message: '请输入子标题2内容', trigger: 'blur' },
                    ],
                },
                note: '',
                stageType: '',
                showPrescription: false,
                planOver: false,
                doctor_words: '',
                dietitian_words: '',
                wellness_words: '',
            }
        },
        methods:{
            addMedication(t){
                t == 0 ? this.medicationList.push({
                    title:'',
                    num:'',
                    reduce_ratio :'',
                    unit_list:[]
                }) : this.insulinList.push({
                    title:'',
                    num:'',
                    reduce_ratio :'',
                    unit_list:[]
                })
            },
            deleteItem(idx,t){
                t == 0 ? this.medicationList.splice(idx,1) : this.insulinList.splice(idx,1);
            },
            watchName(title,cb,idx,type){
                medicineSearch({
                    title,
                    type
                }).then( res =>{
                    cb(res.data.data);
                })
            },
            searchDrug(info,idx,t){
                info.reduce_ratio;
                t == 0 ? this.medicationList[idx] = info : this.insulinList[idx] = info;
            },
            changeUnit(idxs,idx,t){
                t == 0 ? this.medicationList[idx].unit = idxs + 1 : this.insulinList[idx].unit = idxs + 1;
            },
            submit(){
                let medicationList = [];
                this.medicationList.map( item =>{
                    medicationList.push({
                        [ item.id ? 'id' : '' ] : item.id ? item.id : '',
                        num: item.num,
                        unit_id: isNaN(item.unit) ? item.unit_id : item.unit_list[item.unit - 1].id ,
                        unit_title: isNaN(item.unit) ? item.unit : item.unit_list[item.unit - 1].title,
                        title: item.title,
                        medicine_id: item.medicine_id,
                        reduce_ratio: item.reduce_ratio
                    })
                })
                this.insulinList.map( item =>{
                    medicationList.push({
                        [ item.id ? 'id' : '' ] : item.id ? item.id : '',
                        num: item.num,
                        unit_id: isNaN(item.unit) ? item.unit_id : item.unit_list[item.unit - 1].id ,
                        unit_title: isNaN(item.unit) ? item.unit : item.unit_list[item.unit - 1].title,
                        title: item.title,
                        medicine_id: item.medicine_id,
                        reduce_ratio: item.reduce_ratio
                    })
                });

                let data = {
                    id:  this.status == '-1' || this.planID == '' ? '' : this.planID,
                    uid: this.patientInfo.id,
                    target_bs_ratio: this.accurate,
                    intro: this.synopsis,
                    start_hba1c: this.hemoglobin,
                    target_hba1c: this.target,
                    start_bmi: this.BMI,
                    target_bmi: this.BMI_Target,
                    medicine_record_detail: JSON.stringify(medicationList)
                }
                reversePlanSave(data).then( res =>{
                    if(res.data.code == 100000){
                        this.$message({
                            message: '保存成功！',
                            type: 'success',
                        })
                        this.showAddPlan = !this.showAddPlan;
                        this.synopsis = '',
                        this.accurate = '',
                        this.hemoglobin = '',
                        this.target = '',
                        this.BMI = '',
                        this.BMI_Target = '',
                        this.medicationList =[
                            {
                                title:'',
                                num:'',
                                reduce_ratio :'',
                                unit_list:[]
                            }
                        ],
                        this.insulinList = [
                            {
                                title:'',
                                num:'',
                                reduce_ratio :'',
                                unit_list:[]
                            }
                        ],
                        this.$emit('uploadDate')
                    }else{
                        this.$message({
                            message: '请填写必填字段！！',
                            type: 'error',
                        })
                    }
                })
            },
            close(){
                this.showAddPlan = false;
            },
            cNote(){
                this.showNote = false;
                this.note = '';
            },
            closes(){
                this.showStage = false;
                this.stageID = '';
                this.stage = {
                    title: '',
                    subTitle: '',
                    times:'',
                    startTimestamp: '',
                    endTimestamp: '',
                    titleChildOne: '',
                    titleChildDetails:'',
                    titleChildTwo: '',
                    titleChildTwoDetails: ''
                };
            },
            planOverClose(){
                this.planOver = false;
            },
            drugClose(){
                this.showPrescription = !this.showPrescription
            },
            showStageAdd(){
                this.showStage = true;
            },
            selectTimes(t){
                let start,end;
                start = time( t[0],  '月日'),
                end = time(t[1], '月日');
                this.stage.startTimestamp = t[0].getTime() / 1000,
                this.stage.endTimestamp = t[1].getTime() / 1000,
                this.stage.times = start + '-' + end;
                this.mon = new Date();
            },
            submitReverse(e){
                this.$refs[e].validate((valid) => {
                    if (valid) {
                        reversePlanPeriodSave({
                            uid: this.patientInfo.id,
                            id: this.stageID,
                            title: this.stage.title,
                            sub_title: this.stage.subTitle,
                            start_time: this.stage.startTimestamp.toString().length > 10 ? this.stage.startTimestamp / 1000: this.stage.startTimestamp,
                            end_time: this.stage.endTimestamp.toString().length > 10 ? this.stage.startTimestamp / 1000 : this.stage.endTimestamp,
                            period_one_title: this.stage.titleChildOne,
                            period_one_content: this.stage.titleChildDetails,
                            period_two_title: this.stage.titleChildTwo,
                            period_two_content: this.stage.titleChildTwoDetails
                        }).then( res =>{
                            if(res.data.code == 100000){
                                this.$message({
                                    message: '保存成功！',
                                    type: 'success',
                                })
                                this.showStage = !this.showStage,
                                this.stageID = '',
                                this.stage = {
                                    title: '',
                                    subTitle: '',
                                    times:'',
                                    startTimestamp: '',
                                    endTimestamp: '',
                                    titleChildOne: '',
                                    titleChildDetails:'',
                                    titleChildTwo: '',
                                    titleChildTwoDetails: ''
                                };
                                this.$emit('uploadDate')
                            }else{
                                this.$message({
                                    message: '请填写必填字段！！',
                                    type: 'error',
                                })
                            }
                        })
                    } else {
                        this.$message({
                            message: '请填写必填字段！！',
                            type: 'error',
                        })
                    }
                });
            },
            timeMonth(t){
                return time( new Date(t * 1000 ) ,'月日')
            },
            plan(id,plan){
                let medicationList = [],insulinList = [];
                plan.medicine_record.map( item =>{
                    medicationList.push({
                        id: item.id,
                        title: item.medicine_title,
                        num: item.medicine_num,
                        reduce_ratio: item.reduce_ratio,
                        unit_list: item.unit_list,
                        unit: item.unit_title,
                        unit_id: item.unit_id,
                        medicine_id: item.medicine_id
                    })
                })
                plan.insulin_record.map( item =>{
                    insulinList.push({
                        id: item.id,
                        title: item.medicine_title,
                        num: item.medicine_num,
                        reduce_ratio: item.reduce_ratio,
                        unit_list: item.unit_list,
                        unit: item.unit_title,
                        unit_id: item.unit_id,
                        medicine_id: item.medicine_id
                    })
                })
                this.synopsis = plan.intro,
                this.accurate = plan.target_bs_ratio,
                this.hemoglobin = plan.start_hba1c,
                this.target = plan.target_hba1c,
                this.BMI = plan.start_bmi,
                this.BMI_Target = plan.target_bmi,
                this.medicationList = medicationList.length > 0 ? medicationList : [
                    {
                        title:'',
                        num:'',
                        reduce_ratio :'',
                        unit_list:[]
                    }
                ],
                this.insulinList = insulinList.length > 0 ? insulinList : [
                    {
                        title:'',
                        num:'',
                        reduce_ratio :'',
                        unit_list:[]
                    }
                ],
                this.showAddPlan = true;
            },
            editPlan(id,item){
                let start,end;
                    start = time( new Date(item.start_time * 1000 ) ,'月日'),
                    end = time( new Date(item.end_time * 1000 ) ,'月日');
                this.stage = {
                    title: item.title,
                    subTitle: item.sub_title,
                    times: start + '-' + end,
                    startTimestamp: item.start_time * 1000,
                    endTimestamp: item.end_time,
                    titleChildOne: item.period_one_title,
                    titleChildDetails:item.period_one_content,
                    titleChildTwo: item.period_two_title,
                    titleChildTwoDetails: item.period_two_content
                }
                this.stageID = id,
                this.showStage = true;
            },
            returnStageType(s,e){
                let today = new Date().getTime() / 1000;
                let title;
                if(e < today){
                    title = '已结束'
                }else if(today > s && today < e){
                    title = '进行中'
                }else if(s > today ){
                    title = '未开始'
                }
                return title;
            },
            upData(){
                this.showPrescription = !this.showPrescription
                this.$emit('uploadDate')
            },
            doctorNote(id){
                this.showNote = true;
                this.planID = id;
            },
            submitNote(val){
                periodNoteSave({
                    id: this.planID,
                    note: val
                }).then( res =>{
                    
                    if(res.data.code == 100000){
                        this.$message({
                            message: '保存成功！',
                            type: 'success',
                        })
                        this.showNote = false;
                        this.note = '';
                        setTimeout( () =>{
                            this.$emit('uploadDate')
                        },1000)
                    }else{
                        this.$message({
                            message: res.msg,
                            type: 'error',
                        })
                    }
                })
            },
            submitPlanOver(){
                planConclusionSave({
                    id: this.planID,
                    doctor_words: this.doctor_words,
                    dietitian_words: this.dietitian_words,
                    wellness_words: this.wellness_words
                }).then( res =>{
                    if(res.data.code == 100000){
                        this.$message({
                            message: '保存成功！',
                            type: 'success',
                        })
                        this.planOver = false,
                        this.doctor_words = '',
                        this.dietitian_words = '',
                        this.wellness_words = '';
                        setTimeout( () =>{
                            this.$emit('uploadDate')
                        },1000)
                    }else{
                        this.$message({
                            message: res.msg,
                            type: 'error',
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped lang="less">
    .reversePlan{
        .content{
            .heads{
                h2{
                    font-size: 1.5rem;
                    span{
                        color: var(--bloodSugarLow);
                    }
                }
            }
            .btn{
                .add{
                    background-color: var(--defalutActiveColor);
                    color: white;
                }
                .addDrug{
                    background-color: #304ECE;
                    color: white;
                    &:hover,
                    &:focus{
                        background-color: #304ECE;
                        border-color: #304ECE !important;
                    }
                }
                .addSum{
                    background-color: var(--bloodSugarHigh);
                    color: white;
                    &:hover,
                    &:focus{
                        background-color: #ffb33f;
                        border-color: #ffb33f !important;
                    }
                }
            }
            .tips{
                border-bottom-color: #F2F2F2;
                img{
                    cursor: pointer;
                    width: 16px;
                }
            }
            &>.title{
                h5{
                    &:nth-child(1){
                        width: 48px;
                    }
                    &:nth-child(2){
                        width: 117px;
                    }
                    &:nth-child(3){
                        width: 117px;
                    }
                }
            }
            &>.target{
                button{
                    background-color: var(--defalutColor);
                    color: white;
                }
            }
            .list{
                border-width: 2px;
                border-color: #F0F0F0;
                .present,
                .target{
                    width: 117px;
                    h1{
                        span{
                            color: #999999;
                            margin-left: 5px;
                            margin-top: 20px;
                        }
                    }
                    h2{
                        color: var(--defalutColor);
                        &.low{
                            color: var(--bloodSugarLow);
                        }
                        &.high{
                            color: var(--bloodSugarHigh);
                        }
                        span{
                            color: #999999;
                            margin-left: 5px;
                            margin-top: 12px;
                        }
                    }
                    .bar{
                        div{
                            width: 26px;
                            height: 14px;
                            &.lone{
                                width: 55px;
                            }
                            &:nth-child(1){
                                background-color: var(--bloodSugarLow);
                                border-radius: 50px 0px 0px 50px;
                            }
                            &:nth-child(2){
                                background-color: var(--defalutColor);
                            }
                            &:nth-child(3){
                                background-color: var(--bloodSugarHigh);
                                border-radius: 0px 50px 50px 0px;
                            }
                            img{
                                width: 20px;
                                bottom: -10px;
                                left: 18px;
                            }
                        }
                    }
                }
                .target{
                    h1{
                        color: var(--defalutActiveColor);
                    }
                }
            }
            .stageForList{
                .edit{
                    .stageType{
                        span{
                            width: 10px;
                            height: 10px;
                            &.ing{
                                background-color: #6DD400;
                            }
                            &.noStart{
                                background-color: #BDBDBD;
                            }
                            &.end{
                                background-color: #00D4C7;
                            }
                        }
                    }
                    button{
                        background: var(--defalutColor);
                        color: white;
                        &.doctor{
                            background-color: #304ECE;
                        }
                    }
                }
                .info{
                    border-color: #F2F2F2;
                    .infos{
                        color: #484848;
                    }
                    .info1{
                        .title{
                            background-color: #EDFAE0;
                        }
                    }
                    .info2{
                        .title{
                            background-color: #E4FAE0;
                        }
                    }
                    .date{
                        .title{
                            background-color: #DFEDF3;
                        }
                    }
                }
                .foodPlan{
                    border-color: #f2f2f2;
                    img{
                        width: 30px;
                        height: 30px;
                    }
                    p{
                        margin-left: auto;
                    }
                }
                .medication{
                    .list>div{
                        width: 50%;
                    }
                }
                .is-disabled{
                    background-color: #c2c2c2 !important;
                    border-color: #c2c2c2 !important;
                }
            }
            .foodList{
                border-color: #F2F2F2;
                .list{
                    p{
                        img{
                            width: 12px;
                        }
                    }
                    .num{
                        >span{
                            width: 28px;
                            height: 1px;
                            background-color: #000;
                        }
                        .start,
                        .down{
                            span{
                                color: #999999;
                            }
                        }
                    }
                }
            }
            .medication{
                .num{
                    .start,
                    .end{
                        font-size: 24px;
                    }
                    &>span{
                        width: 28px;
                        height: 1px;
                        background-color: #000;
                    }
                }
                .scale{
                    h1{
                        font-size: 24px;
                        color: var(--defalutColor);
                        &.high{
                            color: var(--bloodSugarLow);
                        }
                        span{
                            color: #484848;
                        }
                    }
                }
            }
            .doctorNote{
                display: flex;
                color: #515a6e;
                border-color: #F2F2F2;
                p{
                    width: ~'calc(100% - 80px)';
                    word-break:break-all;
                    word-wrap:break-word;
                    margin-left: auto;
                }
            }
        }
        .notData{
            width: 100%;
            height: ~'calc(100vh - 395px)';
            border: 1px solid #0c0c0c;
            .button{
                background-color: var(--defalutActiveColor);
                color: white;
                border: unset;
            }
        }
        & /deep/.el-dialog{
            .el-dialog__body{
                padding-bottom: 0px;
            }
            border-radius: 6px;
            padding: 40px;
            width: 800px;
            .title{
                position: absolute;
                top: 55px;
                font-size: 16px;
                p{
                    font-size: 20px;
                }
            }
            .planInfo{
                .form{
                    .form-item{
                        .input{
                            .title{
                                position: unset;
                                width: 80px;
                            }
                            .inputs{
                                width: 200px;
                                .el-input__suffix{
                                    display: flex;
                                    align-items: center;
                                }
                            }
                        }
                        .el-form-item{
                            margin-bottom: 0px !important;
                            .el-form-item__error{
                                padding-left: 10px;
                            }
                        }
                        .triple{
                            .input{
                                .title{
                                    width: 80px;
                                }
                                .el-input-group__append{
                                    padding: 0px 10px;
                                }
                                &:nth-child(n+2){
                                    .inputs{
                                        width: 110px;
                                    }
                                }
                                &:nth-child(2){
                                    .title{
                                        width: 40px;
                                    }
                                }
                                &:nth-child(3){
                                    .title{
                                        width: 70px;
                                    }
                                }
                                .el-input__inner{
                                    min-width: 40px;
                                }
                            }
                            i{
                                right: -35px;
                                top: 18px;
                                font-size: 22px !important;
                                color: var(--black) !important;
                                cursor: pointer;
                            }
                        }
                        .medication,
                        .insulin{
                            .addMedication{
                                color: white;
                                background: var(--bloodSugarHigh);
                                &:hover,
                                &:focus,
                                &:active{
                                    border-color: var(--bloodSugarHigh) !important;
                                }
                            }
                        }
                    }
                }
                .submit{
                    width: 350px;
                    height: 55px;
                    background-color: var(--defalutColor);
                    color: white;
                }
            }

        }
        .addStage /deep/ .el-dialog{
            .el-input{
                width: 245px;
                .el-input__inner::placeholder{
                    font-size: 12px;
                }
            }

            .times{
                input{
                    text-align: right;
                }
            }
            
            img{
                width: 22px;
                height: 22px;
            }
            .btn{
                width: 40px;
                height: 40px;
                padding: 0px;
                border: unset;
            }
            .days{
                top: 0px;
                left: 0px;
                height: 50px;
                width: 55px;
                background-color: unset;
                border: unset;
                > *{
                    opacity: 0;
                    cursor: pointer;
                }
                > input{
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    z-index: 99999;
                }
                > span{
                    display: none;
                }
                .el-popper{
                    opacity: 1;
                }
            }
            .tips{
                color: #999999;
                padding-left: 80px;
                span{
                    color: var(--defalutColor);
                }
            }
            .double{
                .lit{
                    max-width: 150px;
                }
            }
            .submit{
                width: 350px;
                height: 55px;
                background-color: var(--defalutColor);
                color: white;
            }
        }
        .addNote,
        .addPlanSum{
            /deep/ textarea{
                color: #3f3f3f;
                margin-top: 10px;
            }
            .submit{
                width: 350px;
                height: 55px;
                background-color: var(--defalutColor);
                color: white;
            }
        }
        .button{
            margin-bottom: 0px;
        }

        &.dark{
            .addDrug,
            .add{
                border-color: rgba(0,0,0,0) !important;
            }
            .content{
                .title{
                    color: #c1c1c1 !important;
                }
                .edit{
                    border: unset !important;
                }
                .tips{
                    border-color: #2c2c2e !important;
                    color: #7f7f7f !important;
                }
                .list{
                    border-color: #2c2c2e !important;
                    .name{
                        color: #c1c1c1 !important;
                    }
                    .present,
                    .target{
                        p{
                            color: #7f7f7f;
                        }
                    }
                    .medicationList,
                    .insulinList{
                        h4{
                            color: #c1c1c1;
                        }
                        p{
                            color: #7f7f7f;
                        }
                        h1{
                            color: #c1c1c1;
                        }
                    }
                }
                .stageForList{
                    .info{
                        border-color: #2c2c2e !important;
                        .title{
                            color: #494949 !important;
                        }
                        .info1{
                            .title{
                                background-color: #b8caa6;
                            }
                        }
                        .info2{
                            .title{
                                background-color: #a7cca0;
                            }
                        }
                        .info3{
                            .title{
                                background-color: #b6cad3;
                            }
                        }
                        .label,
                        .date{
                            color: #c1c1c1 !important;
                        }
                    }
                    .foodPlan{
                        border-color: #2c2c2e !important;
                        color: #c1c1c1;
                    }
                    .foodList{
                        color: #c1c1c1;
                        border-color: #2c2c2e;
                        .list{
                            .num{
                                >span{
                                    background-color: #c1c1c1;
                                }
                            }
                        }
                    }
                    .medication{
                        .list{
                            .num{
                                >span{
                                    background-color: #c1c1c1;
                                }
                            }
                        }
                        .scale{
                            h1{
                                span{
                                    color: #7f7f7f;
                                }
                            }
                        }
                    }
                    .edit{
                        .stageType{
                            p{
                                color: #c1c1c1;
                            }
                        }
                        button{
                            border: unset !important;
                        }
                    }
                }

                .doctorNote{
                    color: #c9c9c9;
                    border-color: #2c2c2e;
                }
            }
            /deep/ .el-dialog{
                background: #202025 !important;
                border-color: #2c2c2e !important;
                .el-dialog__body{
                    .title,
                    .el-form-item__label{
                        color: #c1c1c1;
                    }
                    input,
                    textarea{
                        background-color: unset !important;
                        border-color: #2c2c2e !important;
                    }
                    .el-input-group__append{
                        background: #0d0e10 !important;
                        border-color: #2c2c2e !important;
                    }
                    button{
                        border: unset !important;
                    }
                    .selectDays{
                        background-color: #0d0e10;
                        border-color: #2c2c2e !important;
                        &:hover{
                            background-color: #000;
                        }
                    }

                    .el-autocomplete-suggestion{
                        background-color: #0d0e10;
                        border-color: #2c2c2e;
                        li{
                            color: #c1c1c1;
                            &:hover{
                                background-color: unset;
                                color: var(--defalutActiveColor);
                            }
                        }
                        .popper__arrow{
                            border-bottom-color: #0d0e10;
                            border-top-color: #0d0e10;
                            &::after{
                                border-top-color: #0d0e10;
                                border-bottom-color: #0d0e10;
                            }
                        }
                    }
                    .el-dropdown-menu{
                        background-color: #0d0e10;
                        border-color: #2c2c2e;
                    }
                }
            }
        }
    }
</style>

<style lang="less">
    .reversePlan.dark .addStage .el-dialog{

        .el-picker-panel{
            background: #202025;
            border-color: #0c0c0c;
            .el-picker-panel__icon-btn,
            .el-date-picker__header-label{
                color: #c1c1c1;
            }
            .el-date-table th{
                color: #c1c1c1;
                border-color: #0c0c0c;
            }
            .is-left{
                border-color: #0c0c0c;
            }
            .in-range{
                div{
                    background-color: #0c0c0c;
                }
            }
            .prev-month span{
                color: #c1c1c1;
            }
            .available{
                span{
                    color: #fff;
                }
                &.current{
                    span{
                        background-color: var(--defalutActiveColor);
                    }
                }
            }
            .normal.disabled div,
            .next-month.disabled div{
                background: #0c0c0c;
                color: #7f7f7f;
            }
            .popper__arrow{
                border-bottom-color: #202025;
                &::after{
                    border-bottom-color: #202025;
                }
            }
        }
    }
</style>