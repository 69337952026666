<template>
    <div class="Patient pages pl-18 pr-18 mt-20" :class="[!Dark ? '' : 'dark', collapsed ? 'hide' : '' ]">
        <div class="scroll pt-20 pb-20 pl-20 pr-20 radius-10" ref="scrollBody" :style="{'overflow-y' : !loading ? 'scroll' : 'hidden' }" v-loading="loading">
            <el-tabs class="pion-rel" v-model="tab">
                <el-tab-pane label="全部" name="0" />
                <el-tab-pane label="动态监测" name="1" />
                <el-tab-pane label="指血监测" name="2" />
                <div class="buttons pion-abs flex">
                    <el-input class="font-12" v-model="keyword" placeholder="用户名/手机号/用户id"></el-input>
                    <el-button class="line-height-32 font-12 ml-15 font-normal" plain icon="el-icon-plus" @click="show = true">添加患者</el-button>
                    <el-button class="line-height-32 font-18 ml-15 border-none" :class="[showType == 1 ? 'show' : '']" @click="showType = 1" icon="el-icon-menu" circle />
                    <div class="button pt-10 pr-10 pb-10 pl-10 pion-rel" :class="[showType == 0 ? 'show' : '']" @click="showType = 0">
                        <i class="el-icon-minus pion-abs font-weight"></i>
                        <i class="el-icon-minus pion-abs font-weight"></i>
                        <i class="el-icon-minus pion-abs font-weight"></i>
                    </div>
                </div>
            </el-tabs>

            <div class="tags font-12 flex">
                <div class="flex align-center" v-for="(item, index) in tags " :key="index" :class="[index == 'serve' ? 'pl-20' : '' ]">
                    <div class="title font-weight">
                        {{ item.title }} ：
                    </div>
                    <div class="tagItem radius-5 pt-2 pb-2 pl-5 pr-5 mr-20" :class="[ items.active ? 'active' : '' ]" @click="check(index,idx)" v-for="(items,idx) in item.list" :key="idx">{{ items.title }}</div>
                </div>
            </div>
            <div class="list pt-17 pl-17">
                <template v-if="showType == 1">
                    <div class="card flex flex-wrap" v-if="tableDate.length > 0">
                        <div class="card-item pb-20 radius-5 mb-20 mr-20" @click="toDetail(item.id,item.agency_nickname)" v-for="(item,index) in tableDate" :key="index">
                            <div class="item-top flex justify-between pt-20 pl-20 pr-20">
                                <div class="top-left">
                                    <div class="name-age flex align-baseline">
                                        <span class="name font-18 text-cut">{{item.agency_nickname}}</span>
                                        <span class="age font-12 ml-5">{{item.age}}岁</span>
                                        <span class="sex font-12 ml-5">{{item.sex_value}}</span>
                                        <img class="pumImg ml-7 pion-rel" v-if="item.pump_record_id > 0 && item.pump_work_status == 1" src="@/assets/images/icons/pum.png" alt="">
                                        <img class="pumImg ml-7 pion-rel" v-if="item.pump_record_id > 0 && item.pump_work_status == 0" src="@/assets/images/icons/pum_.png" alt="">
                                    </div>
                                    <div class="time font-12">{{item.last_blood_sugar_time || '--'}}</div>
                                    <div class="measure flex mt-10">
                                        <div class="number-big font-weight" :class="[item.last_blood_sugar == '' ? 'notDate' : (item.last_blood_sugar_status=='high'?'high': item.last_blood_sugar_status=='low'? 'low' : 'normal') ]">
                                            {{item.last_blood_sugar || '¯.¯'}}
                                        </div>
                                        <div class="number-small ml-5">
                                            <div class="small-top mt-10" :class=" !item.device_id ? 'opacity' : '' ">
                                                <template v-if="item.device_id && item.device_id != '0'">
                                                    <div class="arrow" v-if="item.last_blood_sugar_trend">
                                                        <i class="el-icon-top font-weight font-18" :style="{'transform': item.last_blood_sugar_trend == 1 ? 'rotate(90deg)' : item.last_blood_sugar_trend == 5 ? 'rotate(45deg)' : item.last_blood_sugar_trend == 20 ? 'rotate(180deg)' : item.last_blood_sugar_trend == 10 ? 'rotate(135deg)' : ''}"></i>
                                                    </div>
                                                    <div class="arrow" v-else>
                                                        <i class="el-icon-top font-18 font-weight" style="transform: rotate(90deg)"></i>
                                                    </div>
                                                </template>
                                            </div>
                                            <div class="small-measure font-12" :class="[item.device_id && item.device_id != '0' ? 'mt_2' : 'mt-20 pt-8']">mmol/L</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="top-right text-right">
                                    <div class="bed">
                                        <div v-show="item.bed != ''" class="font-12 text-center el-tag el-tag--success">{{ item.bed != '' ? item.bed : '' }}</div>
                                    </div>
                                    <div class="image text-center" v-if="item.device_id && item.device_id != '0' ">
                                        <img :src="item.device_type_icon ? item.device_type_icon : ''" alt />
                                        <div class="font-12">正在监测</div>
                                    </div>
                                </div>
                            </div>
                            <div class="item-middle pl-10 pr-10" v-if="item.device_id && item.device_id != '0'">
                                <ChartsLine
                                    style="height:150px"
                                    v-if="item.blood_sugar_list && item.blood_sugar_list.data"
                                    :value="item.blood_sugar_list.data"
                                    :target_high="item.blood_sugar_list&&item.blood_sugar_list.target_high?item.blood_sugar_list.target_high:0"
                                    :target_low="item.blood_sugar_list&&item.blood_sugar_list.target_low?item.blood_sugar_list.target_low:0"
                                    :specialArr="item.blood_sugar_list.life_record_coord"
                                    :type="false"
                                    :longPass="null"
                                />
                                <div class="empty" v-else>
                                    <template v-if="loading == false">
                                        <div class="box-center">
                                            <img src="@/assets/images/error/empty.png" v-if="!Dark" alt />
                                            <img src="@/assets/images/error/dark_empty.png" v-else alt />
                                            <p>暂无数据</p>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div class="item-middle pl-10 pr-10" v-else>
                                <div class="statistics flex justify-between align-center">
                                    <div class="zhijian item-box flex justify-center align-center">
                                        <div class="title font-12">{{item.bgm_count}}次</div>
                                        <div class="sub_title font-14">指尖血糖监测</div>
                                    </div>
                                    <div class="dongtai item-box flex justify-center align-center">
                                        <div class="title font-12">{{item.cgm_count}}周期</div>
                                        <div class="sub_title font-14">动态血糖监测</div>
                                    </div>
                                </div>
                            </div>
                            <div class="item-bottom flex justify-between align-center pl-20 pr-20 pt-15" v-if="item.liferecord_total">
                                <div class="ku flex align-center font-12">
                                    <img class="mr-5" src="@/assets/images/icons/u.png" alt />
                                    {{item.liferecord_total.foodrecord_total || '--'}}克
                                </div>
                                <div class="u flex align-center font-12">
                                    <img class="mr-5" src="@/assets/images/icons/ku.png" alt />
                                    {{item.liferecord_total.insulin_total || '--'}}U
                                </div>
                                <div class="ci flex align-center font-12">
                                    <img class="mr-5" src="@/assets/images/icons/ci.png" alt />
                                    {{item.liferecord_total.medicinerecord_total || '--'}}次
                                </div>
                                <div class="kal flex align-center font-12">
                                    <img class="mr-5" src="@/assets/images/icons/kal.png" alt />
                                    {{item.liferecord_total.sportsrecord_total || '--'}}千卡
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="empty" v-else>
                        <template v-if="loading == false">
                            <div class="box-center">
                                <img src="@/assets/images/error/empty.png" v-if="!Dark" alt />
                                <img src="@/assets/images/error/dark_empty.png" v-else alt />
                                <p>暂无数据</p>
                            </div>
                        </template>
                    </div>
                </template>
                <template v-if="showType == 0">
                    <div class="tableList" v-if="tableDate.length > 0">
                        <Table :data="tableDate" :Dark="Dark" @to="toDetail" @del="deleteUser" />
                    </div>
                    <div class="empty" v-else>
                        <template v-if="loading == false">
                            <div class="box-center">
                                <img src="@/assets/images/error/empty.png" v-if="!Dark" alt />
                                <img src="@/assets/images/error/dark_empty.png" v-else alt />
                                <p>暂无数据</p>
                            </div>
                        </template>
                    </div>
                </template>
                <div class="pages flex justify-center" v-if="loading == false && tableDate.length > 0">
                    <el-pagination background layout="prev, pager, next, jumper" @current-change="selectPage" :current-page="page" prev-text="上一页" next-text="下一页" :total="count" :page-size="16"></el-pagination>
                </div>
            </div>
        </div>
        <AddPatient :Edit="false" :Show="show" @ok="submit" :Dark="Dark" @close="show = false" />
    </div>
</template>

<script>

    import { getUsersList, deleteUser } from 'quest';
    import { ChartsLine } from '_c/Charts/index';
    import { AddPatient, Table } from '_c/Model/index';
    export default {
        components: {
            ChartsLine,
            AddPatient,
            Table
        },
        props:{
            Dark: Boolean,
            collapsed: Boolean
        },
        watch:{
            'tab'(){
                this.loading = true;
                this.getUsersList();
            },
            'showType'(){
                this.loading = true;
                this.getUsersList();
            },
            'keyword'(){
                this.loading = true;
                this.getUsersList();
            },
            'page'(){
                this.loading = true;
                this.getUsersList();
            }
        },
        data() {
            return {
                notDate: require('@/assets/images/error/notheader.png'),
                tab: '0',
                keyword: '',
                showType: 1,
                loading: true,
                page: 1,
                suffer_type: '',
                treatment_plan: '',
                is_pump: -1,
                is_in_hospital: -1,
                tagList: [],
                tableDate: [],
                count: 0,
                show: false,
                hiddenPage: true,
                tags: {
                    pum: {
                        title: '胰岛素泵',
                        list: [
                            {
                                title: '全部',
                                type: 4, 
                                active: true
                            },
                            {
                                title: '佩戴中',
                                type: 4, 
                                active: false
                            },
                            {
                                title: '未佩戴',
                                type: 4, 
                                active: false
                            }
                        ]
                    },
                    serve: {
                        title: '服务状态',
                        list: [
                            {
                                title: '全部',
                                type: 4, 
                                active: true
                            },
                            {
                                title: '院内',
                                type: 4, 
                                active: false
                            },
                            {
                                title: '院外',
                                type: 4, 
                                active: false
                            }
                        ]
                    }
                }
            };
        },
        methods: {
            selectPage(page){
                this.page = page;
                this.$refs.scrollBody.scrollTop = 0;
            },
            getUsersList(){
                getUsersList({
                    type: this.showType, //默认为0 0为列表展示 1为方格展示
                    page: this.page, // 页码
                    limit: 16, // 每页数量
                    source: this.tab, // 列表展示还是折线图展示
                    keyword: this.keyword, // 关键词 如果是手机号则搜索手机号 如果非手机号的整数 搜索id 如果是字符串 搜索昵称
                    suffer_type: this.suffer_type, // 患病类型
                    treatment_plan: this.treatment_plan, // 治疗方案
                    is_pump: this.is_pump,
                    is_in_hospital: this.is_in_hospital 
                }).then( res =>{
                    if(res.data.code == 100000){
                        this.tagList = res.data.data.tag;
                        this.tableDate = res.data.data.list;
                        this.count = res.data.data.count;
                        this.loading = false;
                    }
                })
            },
            deleteUser(id){
                this.$confirm('确定要删除该患者吗？','提示',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    customClass: !this.Dark ? '' : 'darkDetailsDialogBox'
                }).then( () =>{
                    deleteUser({uid: id}).then( res => {
                        if(res.data.code == 100000){
                            this.$message({
                                type: 'success',
                                message: res.data.msg
                            });
                            this.getUsersList();
                        }
                    })
                }).catch(() => {

                });
            },
            check(key,idx) {
                this.tags[key].list[idx].active = true;
                let type = -1;
                this.tags[key].list.map( (item,index) =>{
                    if(idx != index ){
                        item.active = false;
                    }
                    if(idx > 0){
                        type = idx == 1 ? 1 : 0;
                    }
                })
                if(key == 'pum'){
                    this.is_pump = type;
                }else{
                    this.is_in_hospital = type;
                }
                this.getUsersList();
            },
            toDetail(id){
                this.$router.push({
                    name: 'PatientDetails',
                    query:{
                        id: id,
                    }
                })
            },
            submit(){
                this.show = false;
                this.getUsersList();
            }
        },
        mounted(){
            this.tab = this.$route.query.tab || '0';
            this.getUsersList();
        }
    }
</script>

<style scoped lang="less">
    .Patient{
        height: ~'calc(100% - 70px)';
        .scroll{
            width: 100%;
            height: 100%;
            overflow-y: scroll;
            background: var(--white);
            transition: .3s;
            .tags{
                color: #9F9F9F;
                padding-left: 17px;
                .title{
                    color: var(--black);
                }
                .tagItem{
                    cursor: pointer;
                    background-color: #F8F8F8;
                    color: #9F9F9F;
                }
                .tagItem.active{
                    background-color: var(--defalutColor) !important;
                    color: var(--white) !important;
                }
            }

            .list{
                width: 100%;

                .card{

                    .card-item{
                        width: ~'calc(25% - 20px)';
                        border: 1px solid #e6e9f0;
                        cursor: pointer;
                        .name-age{
                            .name{
                                max-width: 100px;
                            }
                            .pumImg{
                                width: 15px;
                                bottom: -2px;
                            }
                        }

                        .age,
                        .sex{
                            color: #9f9f9f;
                        }

                        .image{
                            width: 50px;
                            margin-left: auto;
                            img{
                                width: 30px;
                                height: 30px;
                            }
                            div{
                                color: #bcbcbc;
                            }
                        }

                        .bed{
                            height: 43px;
                            color: var(--black);
                            margin-top: -2px;
                            div{
                                padding-top: 2px;
                                padding-bottom: 2px;
                                height: unset;
                                line-height: unset;
                            }
                        }

                        .time{
                            color: #7a7a7a;
                        }

                        .measure{

                            .number-big{
                                font-size: 38px;
                                &.normal{
                                    color: var(--defalutColor) !important;
                                }
                                &.high{
                                    color: var(--bloodSugarHigh) !important;
                                }
                                &.low{
                                    color: var(--bloodSugarLow) !important;
                                }
                            }

                            .number-small{
                                .small-measure{
                                    &.mt_2{
                                        margin-top: -5px;
                                    }
                                    color: var(--black);
                                }

                            }
                        }
                        .item-middle{

                            height: 120px;

                            .statistics {
                                background: #fcfcfc;
                                height: 100%;

                                .item-box {
                                    flex: 1;
                                    flex-direction: column;

                                    .title {
                                        font-family: PingFangSC-Regular, PingFang SC;
                                        color: #242424;
                                    }
                                }

                                .title{
                                    color: #242424;
                                }

                                .sub_title{
                                    color: #515a6e;
                                }
                            }
                        }

                        .item-bottom{
                            div {
                                color: #aaaaaa;
                                img {
                                    width: 16px;
                                    height: 16px;
                                }
                            }
                        }
                    }
                }
                .empty{
                    height: ~'calc(100vh - 300px )';
                }
            }
        }

        .scroll /deep/ .el-tabs{

            .el-tabs__nav-wrap:after{
                height: 1px;
                background-color: #dcdee2;
            }

            .el-tabs__nav-scroll{
                padding-left: 20px;
            }

            .el-tabs__active-bar{
                height: 2px;
                background-color: var(--defalutColor);
            }
            
            .el-tabs__item.is-active{
                font-weight: 100;
                color: var(--defalutColor);
            }
            
            .el-tabs__item{
                transition: .3s;
            }
            
            .el-tabs__item:hover{
                transition: .3s;
                color: var(--defalutColor);
            }

            .el-tabs__content{
                overflow: visible;
            }

            .buttons{
                top: -60px;
                right: 0px;
                z-index: 999;
                .el-input{
                    width: 350px;
                    height: 32px;
                }

                input{
                    width: 350px;
                    height: 32px;

                    &::placeholder{
                        font-size: 12px;
                    }

                    &:hover,
                    &:focus{
                        border-color: var(--defalutColor);
                    }
                }

                button{
                    height: 32px;
                    width: 120px;
                    padding: 0px 10px;
                    transition: .3s;

                    &:hover{
                        background: none;
                        border-color: var(--defalutColor) !important;
                        color: var(--defalutColor) !important;
                        transition: .3s;
                    }
                    &:focus{
                        background: none;
                        border-color: #dcdfe6;
                        color: #606266;
                    }
                }

                button.border-none{
                    width: auto;
                    color: #e8e8e8;
                }

                .button{
                    width: 32px;
                    height: 32px;
                    cursor: pointer;
                    color: #e8e8e8;

                    i{
                        transition: .3s;
                    }

                    i:nth-of-type(1){
                        top: 2px;
                    }

                    i:nth-of-type(2){
                        top: 7px;
                    }

                    i:nth-of-type(3){
                        top: 12px;
                    }

                    &:hover,
                    &:focus{
                        i{
                            color: var(--defalutColor);
                            transition: .3s;
                        }
                    }
                }

                .show{
                    color: #515a6e !important;
                }
            }
        }

        .scroll /deep/ .pages{
            .btn-prev,
            .btn-next{
                padding: 0px 20px;
                float: left;
                background: none;
                transition: .3s;
                &:hover{
                    color: var(--defalutColor);
                    transition: .3s;
                }
                &[disabled]{
                    color: #c0c4cc;
                }
            }
            .el-pager{
                float: left;
                li{
                    background: none;
                    border: 1px solid #dcdee2;
                    transition: .3s;
                    &:hover{
                        border-color: var(--defalutColor);
                        color: var(--defalutColor);
                        transition: .3s;
                        cursor: pointer;
                    }
                    &.active{
                        border-color: var(--defalutColor);
                        color: var(--defalutColor);
                        transition: .3s;
                    }
                }
            }
            .el-input__inner:focus{
                border-color: var(--defalutColor);
            }
        }

        .tableList /deep/ .listTable{

            &::before,
            .el-table__fixed::before{
                content: unset;
            }

            .el-table__fixed-right:before{
                content:  unset;
            }

            th.el-table__cell{
                background-color: #fcfdff;
                height: 52px;
                border: none;
            }

            .el-table__row{
                .el-table__cell{
                    border: none;
                    height: 84px;

                    .normal{
                        color: var(--defalutColor);
                        span{
                            color: #606266;
                        }
                    }

                    .high{
                        color: var(--bloodSugarHigh);
                        span{
                            color: #606266;
                        }
                    }

                    .low{
                        color: var(--bloodSugarLow);
                        span{
                            color: #606266;
                        }
                    }
                }
            }

            .header{
                width: 37px;
                height: 37px;
            }
        }
        &.dark{
            .scroll{
                background: #202025 !important;
                transition: .3s;
                .tags{
                    .title{
                        color: var(--white);
                    }
                    .tagItem{
                        background-color: #0c0c0c;
                        color: #c1c1c1;
                    }
                }
                .card{
                    .card-item{
                        border-color: #2c2c2e !important;

                        .name,
                        .number-big,
                        .sub_title,
                        .bed{
                            color: #c1c1c1 !important;
                        }
                        .age,
                        .sex,
                        .time,
                        .arrow>i,
                        .small-measure,
                        .item-bottom>div{
                            color: #ccc !important;
                        }
                    }
                }
            }

            .scroll /deep/ .el-tabs{

                .el-tabs__item{
                    color: #c1c1c1 !important;
                    &.is-active{
                       color: var(--defalutColor) !important; 
                    }
                }

                .el-tabs__nav-wrap:after{
                    background-color: #2c2c2e;
                }

                .buttons{
                    input{
                        background: unset;
                        border-color: #2c2c2e;

                        &::placeholder{
                            color: #7f7f7f;
                        }
                    }
                    button{
                        background: unset;
                        color: #c1c1c1 !important;
                        border-color: #2c2c2e;
                    }
                }
            }

            .statistics{
                background: #2a2a31 !important;
                .title{
                    color: #7f7f7f !important;
                }
            }

            & /deep/ .pages{
                .btn-prev{
                    color: #c1c1c1 !important;
                    &[disabled]{
                        color: #2c2c2e !important;
                        &:hover{
                            color: #2c2c2e !important;
                        }
                    }
                    &:hover{
                        color: var(--defalutColor) !important;
                    }
                }
                .number{
                    color: #c1c1c1 !important;
                    border-color: #2c2c2e !important;
                    &.active{
                        color: var(--defalutColor) !important;
                        border-color: var(--defalutColor)!important;
                    }
                }
                .btn-next{
                    color: #c1c1c1 !important;
                    &[disabled]{
                        color: #2c2c2e !important;
                        &:hover{
                            color: #2c2c2e !important;
                        }
                    }
                    &:hover{
                        color: var(--defalutColor) !important;
                    }
                }
                .el-pagination__jump{
                    color: #c1c1c1 !important;
                    input{
                        background-color: unset;
                        border-color: #2c2c22;
                        color: #c1c1c1;
                    }
                }
            }

            .tableList /deep/ .listTable{
                background-color: unset;

                thead{
                    color: white;
                }

                th.el-table__cell{
                    background-color: black !important;
                    &>div{
                        color: white;
                    }
                }

                tr{
                    background: unset;
                }

                .el-table__row{

                    td>div{
                        color: white !important;
                    }
                    .newbood_sugar{
                        span{
                            color: #ccc !important;
                        }
                    }
                }
            }

        }
    }

    @media screen {
        @media (max-width: 1024px) and (min-width: 375px) {
            .Patient{
                .card{
                    .card-item{
                        width: ~'calc( 100% / 3 - 20px)' !important;
                    }
                }
            }
        }
    }

    @media screen {
        @media (min-width: 1024px) and (max-width: 1366px) {
            .Patient{
                .card{
                    .card-item{
                        width: ~'calc( 100% / 3 - 20px)' !important;
                    }
                }
            }
        }
    }

</style>

<style lang="less">

    .popover{
        .line{
            margin: 10px 0px;
        }
        .pop-button button{
            color: #9f9f9f;
            margin: 0px;
            width: 100%;
        }
    }

    .darkDetailsDialogBox{
        background-color: #0c0c0c;
        border-color: #0c0c0c;
        .el-message-box__title,
        .el-message-box__message{
            color: #c1c1c1;
        }
        .el-message-box__btns{
            .el-button:nth-child(1){
                background-color: #202025;
                border-color: #202025;
                color: #c1c1c1;
                &:hover{
                    color: var(--defalutColor) !important;
                }
            }
        }
    }
</style>