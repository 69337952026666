import axios from './request.create'
import api from './Api'

// 登陆接口
export const login = (info) => {
    return axios.request({
        url: api.login,
        isLogin: true,
        method: 'post',
        data: info,
    })
}

// 获取用户信息
export const getUserInfo = () => {
    return axios.request({
        url: api.userInfo,
        isLogin: false,
        method: 'post',
    })
}

// 获取机构信息
export const agencyInfo = () => {
    return axios.request({
        url: api.agencyInfo,
        isLogin: false,
        method: 'post',
    })
}

// 获取登陆信息
export const getIndexDate = () => {
    return axios.request({
        url: api.getIndexDate,
        isLogin: false,
        method: 'post',
    })
}

// 获取用户列表
export const getUsersList = (info) => {
    return axios.request({
        url: api.getUsersList,
        data: info,
        method: 'post',
        isLogin: false
    })
}

// 获取医生列表
export const getDoctorList = ( info ) => {
    return axios.request({
        url: api.getDoctorList,
        isLogin: false,
        data: info,
        method: 'post'
    })
}

// 发送验证码
export const sendCode = (info) => {
    return axios.request({
        url: api.sendCode,
        method: 'post',
        data: info,
    })
}

// 校验验证码
export const checkCode = (info) => {
    return axios.request({
        url: api.checkCode,
        method: 'post',
        data: info
    })
}

// 新增/编辑患者
export const addEditUser = (info) => {
    return axios.request({
        url: api.addUser,
        method: 'post',
        data: info
    })
}

// 保存患者
export const userSave = (info) => {
    return axios.request({
        url: api.userSave,
        method: 'post',
        data: info
    })
}

// 删除患者
export const deleteUser = (info) => {
    return axios.request({
        url: api.deleteUser,
        method: 'post',
        data: info
    })
}

// 获取患者详情
export const getPatientInfo = (info) =>{
    return axios.request({
        url: api.getPatientInfo,
        method: 'post',
        data: info
    })
}

// 编辑用户姓名
export const editUserName = info =>{
    return axios.request({
        url: api.editUserName,
        method: 'post',
        data: info
    })
}

// 编辑用户备注
export const editUserNote = info =>{
    return axios.request({
        url: api.editUserNote,
        method: 'post',
        data: info
    })
}

// 编辑每日数据解读
export const editInterpret = info =>{
    return axios.request({
        url: api.editInterpret,
        method: 'post',
        data: info
    })
}

// 编辑/新增备注
export const addNote = info =>{
    return axios.request({
        url: api.addNote,
        method: 'post',
        data: info
    })
}

// 获取患者信息
export const getUserHealthData = (info) => {
    return axios.request({
      url: api.getUserHealthData,
      method: 'post',
      data: info
    })
  }

// 获取指定天数的信息
export const getDataByDate = (info) => {
    return axios.request({
        url: api.getDataByDate,
        method: 'post',
        data: info
    })
}

// 获取指定天数饮食信息
export const getUserLifeRecordByDay = (info) => {
    return axios.request({
        url: api.getUserLifeRecordByDay,
        method: 'post',
        data: info
    })
}

// 获取数据统计(已过期)
export const getDeviceStatByDeviceId = (info) => {
    return axios.request({
        url: api.getDeviceStatByDeviceId,
        method: 'post',
        data: info
    })
}

// 数据统计最新接口

export const getDeviceStatByDays = (info) => {
    return axios.request({
        url: api.getDeviceStatByDays,
        method: 'post',
        data: info
    })
}

// 获取数据统计[ 指尖血 数据]
export const getStatByDays = (info) => {
    return axios.request({
        url: api.getStatByDays,
        method: 'post',
        data: info
    })
}

// 佩戴记录列表
export const getUserDeviceList = (info) =>{
    return axios.request({
        url: api.getUserDeviceList,
        method: 'post',
        data: info
    })
}

// 获取高低血糖列表
export const getBloodSugarNoticeList = (info) =>{
    return axios.request({
        url: api.getBloodSugarNoticeList,
        method: 'post',
        data: info
    })
}

// 获取任务列表
export const getTaskList = (info) => {
    return axios.request({
        url: api.getTaskList,
        method: 'post',
        data: info
    })
}

// 获取方案列表
export const getDoctorPlanList = (info) => {
    return axios.request({
        url: api.getDoctorPlanList,
        method: 'post',
        data: info
    })
}

// 获取患者方案模版
export const getUserPlan = (info) =>{
    return axios.request({
        url: api.getUserPlan,
        method: 'post',
        data: info
    })
}

// 获取模版方案
export const templateGetList = (info) =>{
    return axios.request({
        url: api.templateGetList,
        method: 'post',
        data: info
    })
}

// 保存方案
export const planSave = (info) =>{
    return axios.request({
        url: api.planSave,
        method: 'post',
        data: info
    })
}

// 添加饮食方案（单条）
export const foodRecordAdd = (info) => {
    return axios.request({
        url: api.foodRecordAdd,
        method: 'post',
        data: info
    })
}

// 添加胰岛素使用方案（单条）
export const medicineRecordAdd = (info) => {
    return axios.request({
        url: api.medicineRecordAdd,
        method: 'post',
        data: info
    })
}

// 添加运动方案（单条）
export const sportsRecordAdd = (info) => {
    return axios.request({
        url: api.sportsRecordAdd,
        method: 'post',
        data: info
    })
}

// 删除食物方案（单条）
export const foodRecordDel = (info) => {
    return axios.request({
        url: '/food_record_new/delete',
        method: 'post',
        data: info
    })
}

// 删除胰岛素使用方案（单条）
export const medicineRecordDel = (info) => {
    return axios.request({
        url: '/medicine_record/delete',
        method: 'post',
        data: info
    })
}

// 删除运动方案（单条）
export const sportsRecordDel = (info) => {
    return axios.request({
        url: '/sports_record/delete',
        method: 'post',
        data: info
    })
}

// 保存方案模版
export const planSaveTemp = (info) =>{
    return axios.request({
        url: api.planSaveTemp,
        method: 'post',
        data: info
    })
}

// 方案详情
export const templateGetInfo = (info) =>{
    return axios.request({
        url: api.templateGetInfo,
        method: 'post',
        data: info
    })
}

// 搜索食物
export const foodSearch = (info) => {
    return axios.request({
        url: api.foodSearch,
        method: 'post',
        data: info
    })
}

// 搜索药物
export const medicineSearch = (info) => {
    return axios.request({
        url: api.medicineSearch,
        method: 'post',
        data: info
    })
}

// 搜索运动
export const sportsSearch = (info) => {
    return axios.request({
        url: api.sportsSearch,
        method: 'post',
        data: info
    })
}

// 删除用户方案
export const userPlanDelete = (data) =>{
    return axios.request({
        url: api.userPlanDelete,
        method: 'post',
        data
    })
}

// 删除方案模版
export const doctorPlanDelete = (data) =>{
    return axios.request({
        url: api.doctorPlanDelete,
        method: 'post',
        data
    })
}

// 获取医院方案
export const getHospitalPrescriptionList = (data) =>{
    return axios.request({
        url: api.getHospitalPrescriptionList,
        method: 'post',
        data
    })
}

// 修改用户信息
export const upDateInfo = (info) => {
    return axios.request({
        url: api.upDateInfo,
        method: 'post',
        data: info
    })
}

// 修改密码
export const upDatePwd = (info) => {
    return axios.request({
        url: api.upDatePwd,
        method: 'post',
        data: info
    })
}

// 获取部门列表
export const getDepartment = (info) => {
    return axios.request({
        url: api.getDepartment,
        method: 'post',
        data: info,
    })
}

// 添加部门
export const addDepartment = (info) => {
    return axios.request({
        url: api.addDepartment,
        method: 'post',
        data: info,
    })
}

// 保存部门
export const departmentSave = (info) => {
    return axios.request({
        url: api.departmentSave,
        method: 'post',
        data: info,
    })
}

// 删除部门
export const departmentDelete = (info) => {
    return axios.request({
        url: api.departmentDelete,
        method: 'post',
        data: info
    })
}

// 获取角色列表
export const getRoleList = () => {
    return axios.request({
        url: api.getRoleList,
        method: 'post',
    })
}

// 添加角色
export const addRole = (info) => {
    return axios.request({
        url: api.addRole,
        method: 'post',
        data: info
    })
}

// 删除角色
export const deleteRole = (info) => {
    return axios.request({
        url: api.deleteRole,
        method: 'post',
        data: info
    })
}

// 添加成员
export const addDoctor = (info) => {
    return axios.request({
        url: api.addDoctor,
        method:'post',
        data: info
    })
}

// 保存成员
export const saveDoctor = (info) => {
    return axios.request({
        url: api.saveDoctor,
        method: 'post',
        data: info
    })
}

// 删除成员
export const deleteDoctor = (info) => {
    return axios.request({
        url: api.deleteDoctor,
        method: 'post',
        data: info
    })
}

// 保存机构logo
export const upLoadLogo = (info) =>{
    return axios.request({
        url: api.upLoadLogo,
        method: 'post',
        data: info
    })
}

// 获取报告数据
export const getReportDate = (info) =>{
    return axios.request({
        url : api.getReportDate,
        method: 'post',
        data: info
    })
}

// 导出数据
export const bloodsugarExport = (info) =>{
    return axios.request({
        url : api.bloodsugarExport,
        method: 'get',
        data: info
    })
}

// 获取逆转方案详情
export const getUserReversePlan = (info) =>{
    return axios.request({
        url : api.getUserReversePlan,
        method: 'post',
        data: info
    })
}

// 保存逆转方案
export const reversePlanSave = (info) =>{
    return axios.request({
        url : api.reversePlanSave,
        method: 'post',
        data: info
    })
}

// 阶段方案保存
export const reversePlanPeriodSave = (info) =>{
    return axios.request({
        url : api.reversePlanPeriodSave,
        method: 'post',
        data: info
    })
}

// 获取处方列表

export const getPrescriptionList = (info) =>{
    return axios.request({
        url : api.getPrescriptionList,
        method: 'post',
        data: info
    })
}

// 处方药保存
export const prescriptionSave = (info) =>{
    return axios.request({
        url : api.prescriptionSave,
        method: 'post',
        data: info
    })
}

// 删除处方
export const prescriptionDelete = (info) => {
    return axios.request({
        url : api.prescriptionDelete,
        method: 'post',
        data: info
    })
}

// 添加医生点评
export const periodNoteSave = (info) =>{
    return axios.request({
        url : api.periodNoteSave,
        method: 'post',
        data: info
    })
}

// 方案总结
export const planConclusionSave = (info) =>{
    return axios.request({
        url : api.planConclusionSave,
        method: 'post',
        data: info
    })
}

// 远程操作
export const remoteSetting = (info) =>{
    return axios.request({
        url : api.remoteSetting,
        method: 'post',
        data: info
    })
}

// 远程操作记录
export const remoteHistory = (info) =>{
    return axios.request({
        url : api.remoteHistory,
        method: 'post',
        data: info
    })
}

// 远程操作闭环
export const remoteSwitchLoop = (info) =>{
    return axios.request({
        url : api.remoteSwitchLoop,
        method: 'post',
        data: info
    })
}

// 获取远程方案列表
export const getPlanList = (info) =>{
    return axios.request({
        url : api.getPlanList,
        method: 'post',
        data: info
    })
}

// 获取系统敏感系数
export const getSystemIsfAndIcr = (info) =>{
    return axios.request({
        url : api.getSystemIsfAndIcr,
        method: 'post',
        data: info
    })
}

// 计算胰岛素敏感系数
export const getIsfByTdd = (info) =>{
    return axios.request({
        url : api.getIsfByTdd,
        method: 'post',
        data: info
    })
}

// 计算碳水敏感系数
export const getIcrByTdd = (info) =>{
    return axios.request({
        url : api.getIcrByTdd,
        method: 'post',
        data: info
    })
}

// 提交保存新方案
export const submitNewPlan = (info) =>{
    return axios.request({
        url : api.submitNewPlan,
        method: 'post',
        data: info
    })
}

// 删除方案
export const planDelete = (info) =>{
    return axios.request({
        url : api.planDelete,
        method: 'post',
        data: info
    })
}

// 切换默认方案
export const changeProfile = (info) =>{
    return axios.request({
        url : api.changeProfile,
        method: 'post',
        data: info
    })
}

// 解除泵绑定
export const remoteRelease = (info) =>{
    return axios.request({
        url : api.remoteRelease,
        method: 'post',
        data: info
    })
}

// 开通胰岛素泵操作
export const openPumpInject = (info) =>{
    return axios.request({
        url : api.openPumpInject,
        method: 'post',
        data: info
    })
}

// 保存床位信息
export const setBed = (info) => {
    return axios.request({
        url: api.setBed,
        method: 'post',
        data: info
    })
}

// 出院
export const userDischarge = (info) =>{
    return axios.request({
        url: api.userDischarge,
        method: 'post',
        data: info
    })
}

// 取消远程操作
export const cancelRecord = (info) =>{
    return axios.request({
        url: api.cancelRecord,
        method: 'post',
        data: info
    })
}