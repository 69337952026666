<template>
    <div class="CenterSystem">
        <div class="head b-pad-20">
            <div class="logo">
                <!-- <img src="@/assets/images/CenterSystem/logo.png" alt=""> -->
                <h4 class="ml-15 font-weight font-23">{{ title }}—中央血糖监护系统</h4>
            </div>
            <div class="time flex align-center">
                <span class="font-20">{{ date }}</span>
                <span class="ml-5 font-20">{{ time }}</span>
                <span class="ml-5 font-20">{{ week }}</span>
            </div>
        </div>
        <el-carousel trigger="click" height="100%" :interval="30000" ref="carousel" @change="selectList" v-loading="loading">
            <el-carousel-item v-for="item in count" :key="item">
                <div class="flex flex-wrap">
                    <div class="list radius-5" v-for="(items, index) in trendList" :key="index">
                        <div class="title b-pad-10 solid-bottom flex justify-between align-center">
                            <p>{{ items.agency_nickname }}</p>
                            <p>{{ items.bed != '' ? items.bed : '' }}</p>
                        </div>
                        <div class="content">
                            <div class="flex justify-center align-center">
                                <div class="text-center">
                                    <h1 class="flex justify-center align-center font-weight solid pson-r-50" :class="[items.last_blood_sugar_status == 'high' ? 'high' : items.last_blood_sugar_status == 'low' ? 'low' : '' ]">{{ items.last_blood_sugar == '' ? '--' : items.last_blood_sugar }} <p class="text-center font-normal">mmol/L</p></h1>
                                    <p class="text-center">监测时间：{{ items.last_blood_sugar_time }}</p>
                                    <p class="text-center">{{ items.sex_value }}<span class="b-mar-5">|</span>{{ items.age }}<span class="b-mar-5">|</span>{{ items.suffer_type_value }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
    import { getUsersList } from 'quest';
    import { time, getItem } from '_js/Cache';
    export default {
        data(){
            return{
                loading: true,
                title: '',
                date: time(new Date(), '月日'),
                time: time(new Date(),'h:m:d'),
                week: time(new Date(),'week'),
                timer: null,
                trendList: [],
                page: 1,
                count: 0,
            }
        },
        methods:{
            getUsersList(){
                getUsersList({
                    type: 1, //默认为0 0为列表展示 1为方格展示
                    page: this.page, // 页码
                    limit: 12, // 每页数量
                    source: 1, // 列表展示还是折线图展示
                }).then( res =>{
                    if(res.data.code == 100000){
                        this.trendList = res.data.data.list;
                        this.count = Math.ceil(res.data.data.count / 12);
                        if(Math.ceil(res.data.data.count / 12) == 1){
                            setInterval( () =>{
                                this.getUsersList()
                            },30000)
                        }
                        this.loading = false;
                        setInterval( () =>{
                            this.time = time(new Date(),'h:m:d')
                        },1000)
                    }
                })
            },
            handleMouseenter(){
                this.timer = setInterval( () =>{
                    this.getUsersList();
                },30000)
            },
            handleMouseleave(){
                clearInterval(this.timer);
            },
            selectList(e){
                this.loading = true;
                this.page = e+1;
                this.getUsersList();
            },
            updateFontSize(){
                const dpr = window.devicePixelRatio || 1;
                const fontSize = Math.min(window.innerWidth / 16, window.innerHeight / 9) / dpr;
                document.querySelector(".CenterSystem").style.fontSize = `${fontSize}px`;
            }
        },
        mounted(){
            this.$refs.carousel.$el.addEventListener('mouseenter', this.handleMouseenter)
            this.$refs.carousel.$el.addEventListener('mouseleave', this.handleMouseleave)
            this.updateFontSize();
            window.addEventListener("resize", this.updateFontSize);
        },
        created(){
            this.getUsersList();
            this.title = getItem('agencyInfo').title;
            Object.defineProperty(window, 'devicePixelRatio', { get: () => 1 });
            window.dispatchEvent(new Event('resize')); // 触发页面更新
            console.log(window.devicePixelRatio); // 现在应该返回 2.5
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.updateFontSize);
        },
    }
</script>

<style lang="less" scoped>
    .CenterSystem{
        width: 100%;
        height: 100%;
        background-color: #05062a;
        .head{
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 75px;
            .logo{
                display: flex;
                height: 100%;
                align-items: center;
                img{
                    width: 255px;
                    height: 40px;
                }
                h4{
                    color: white;
                }
            }
            .time{
                height: 100%;
                span{
                    color: white;
                }
            }
        }
        .el-carousel{
            width: 100%;
            height: ~'calc(100% - 75px)';
            padding: 20px;
            padding-top: 0px;
            padding-bottom: 30px;
            overflow: hidden;
            .flex{
                width: 100%;
                height: 100%;
                .list{
                    background-color: #142c4b;
                    width: ~'calc(25% - 15px)';
                    height: ~'calc( ( 100% - 45px) / 3 )';
                    margin-left: 20px;
                    margin-top: 20px;
                    border-radius: 8px;
                    &:nth-child(4n + 1){
                        margin-left: 0px;
                    }
                    &:nth-child(-n + 4){
                        margin-top: 0px;
                    }
                    .title{
                        color: white;
                        height: 0.525em;
                        border-color: #05062a;
                        p{
                            font-size: .155em;
                        }
                    }
                    .content{
                        width: 100%;
                        height: ~'calc( 100% - 35px)';
                        h1{
                            width: 3em;
                            height: 3em;
                            margin: 0 auto;
                            font-size: .35em;
                            color: white;
                            border-color: var(--defalutColor);
                            border-width: 3px;
                            flex-wrap: wrap;
                            padding-top: .2em;
                            @media (min-width: 1920px) {                            
                                margin-top: -.2em;
                            }
                            &.high{
                                border-color: var(--bloodSugarHigh);
                                box-shadow: 0 0 10px var(--bloodSugarHigh);
                                -webkit-animation-timing-function: ease-in-out;
                                -webkit-animation-name: shadow-high;
                                -webkit-animation-duration: 1500ms;
                                -webkit-animation-iteration-count: infinite;
                                -webkit-animation-direction: alternate;
                            }
                            &.low{
                                border-color: var(--bloodSugarLow);
                                box-shadow: 0 0 10px var(--bloodSugarLow);
                                -webkit-animation-timing-function: ease-in-out;
                                -webkit-animation-name: shadow-low;
                                -webkit-animation-duration: 1500ms;
                                -webkit-animation-iteration-count: infinite;
                                -webkit-animation-direction: alternate;
                            }
                            p{
                                width: 100%;
                                margin-top: -2.55em;
                                font-size: .255em;
                            }
                        }
                        p{
                            color: white;
                            font-size: .155em;
                            margin-top: .25em;
                            &:nth-child(2){
                                padding-top: .25em;
                            }
                        }
                    }
                }
            }

            /deep/ .el-carousel__arrow{
                background-color: rgba(255,255,255,.5);
            }
            /deep/ .el-carousel__indicators--horizontal{
                bottom: -0.25em;
                .el-carousel__indicator{
                    padding: 5px;
                    .el-carousel__button{
                        width: 10px;
                        height: 10px;
                        border-radius: 50px;
                    }
                }
            }
        }

        /deep/ .el-loading-mask{
            background-color: rgba(0,0,0,.9);
        }
    }

    @keyframes shadow-high {
        0% {
            opacity: .4;
            box-shadow: 0 2px 2px rgba(249, 237, 80, 0.4), 0 2px 2px rgba(249, 237, 80, 0.1) inset;
        }

        100% {
            opacity: 1;
            border: 3px solid rgba(255, 170, 41, 0.7);
            box-shadow: 0 2px 10px #e2992a, 0 2px 40px #FFAA29 inset;
        }
    }
    @keyframes shadow-low {
        0% {
            opacity: .4;
            box-shadow: 0 2px 2px rgba(236, 54, 45, 0.4), 0 2px 2px rgba(236, 54, 45, 0.1) inset;
        }

        100% {
            opacity: 1;
            border: 3px solid rgba(236, 54, 45, 0.7);
            box-shadow: 0 2px 10px #9b2d2d, 0 2px 40px #C03434 inset;
        }
    }
</style>